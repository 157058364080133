import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import EYE from "../../../assets/images/users/view.svg";
import ASSESMENT_FILE from "../../../assets/images/users/assesments.svg";
import CarePlanInformationDialog from "./carePlanInformationDialog";
import dayjs from "dayjs";

export default function HistoricalAssessmentCards({
  index,
  inActiveAssessment,
  inactive,
  assessmentQuestions,
  assessmentName,
  user,
  selectedCard,
  assessmentId,
  name,
}) {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    setDialogOpen(false);
  };

  return (
    <Box style={{ paddingBottom: "4px" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          border: "2px solid #F0FCFA",
          minheight: "60px",
          padding: "5px 15px",
          borderRadius: "10px",
          marginBottom: "10px",
          background: "#C0EBE4",
          cursor: "pointer",
          border: "2px solid #F0FCFA",
          "&:hover": {
            border: "2px solid #279989", // Correct way to use :hover in MUI sx
          },
        }}
        onClick={handleDialogOpen} // Open dialog on div click
      >
        <Grid item xs={2} sx={{ margin: "0 2px" }}>
          <Box>
            <img src={ASSESMENT_FILE} alt="user logo" height="35px" />
          </Box>
        </Grid>
        <Grid item xs={8} sx={{ margin: "0 1px" }}>
          <Box sx={{ paddingLeft: "10px" }}>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Lora",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                color: "#48464D",
                paddingBottom: 0,
              }}
              variant="h5"
            >
              {inactive.sign_2 || inactive.sign_1 || inactive.sign_1}
            </Typography>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Lora",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "700",
                color: "#48464D",
              }}
              variant="h6"
            >
              Closed date:
              {inactive.date_2
                ? dayjs(inactive.date_2).format("DD-MM-YYYY")
                : dayjs(inactive.date_1).format("DD-MM-YYYY")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1} sx={{ margin: "0 1px" }}>
          {/* </Box> */}

          <Box>
            <img
              src={EYE}
              // alt={index}
              style={{ width: "22px", height: "22px" }}
            />
          </Box>
        </Grid>
      </Grid>
      {isDialogOpen && (
        <CarePlanInformationDialog
          inActiveAssessment={inActiveAssessment}
          assessmentQuestions={assessmentQuestions}
          assessmentName={assessmentName}
          user={user}
          open={isDialogOpen}
          selectedCard={selectedCard}
          onClose={handleDialogClose}
          index={index}
          assessmentId={assessmentId}
          inactive={inactive} // Pass assessment data
          name={name}
        />
      )}
    </Box>
  );
}
