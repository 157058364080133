import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  IconButton,
  DialogContent,
  Dialog,
  Typography,
  Button,
  Grid,
  DialogActions,
} from "@mui/material";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import EndofService from "../../../assets/images/clients/endofservice.svg";
import Dropdown from "../../reusableComponents/Dropdown";
import Calendar from "../../reusableComponents/Calendar";
import Name from "../../reusableComponents/Name";
import { getCookie } from "../../../utils/utils";

export default function ServiceEditDialog({
  openDialog,
  clientId,
  handleCloseDialog = () => {},
}) {
  const list = [
    { id: 1, text: "Client Hospitalization" },
    { id: 2, text: "Family Support" },
    { id: 3, text: "Client Vacation" },
    { id: 4, text: "Service Review" },
    { id: 5, text: "Change in Condition" },
    { id: 6, text: "Financial Constraints" },
  ];

  // Initial form state
  const initialFormState = {
    reason: "",
    service_start_date: "",
    service_end_date: "",
    notes: "",
  };

  const [form, setForm] = useState(initialFormState);

  const reasonRef = useRef();
  const serviceStartDateRef = useRef();
  const serviceEndDateRef = useRef();
  const additionalNotesRef = useRef();

  useEffect(() => {
    const fetchServiceStatus = async () => {
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/get-clientservicestatus/${clientId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          const info = data.data.map((item) => ({
            reason: item.reason,
            service_start_date: item.serviceStartTime,
            service_end_date: item.serviceEndTime,
            notes: item.notes,
          }));
          setForm(info[0]);
        } else {
          console.log("API request failed with status:", response.status);
          setForm(initialFormState);
        }
      } catch (e) {
        console.log("Error fetching data:", e.message);
        setForm(initialFormState);
      }
    };

    fetchServiceStatus();
  }, [openDialog]);

  const putServiceStatusUpdate = async (updatedData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/update-clientservicestatus/${clientId}`;
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        console.log("Updated Service Status Details");
        handleCloseDialog();
      } else {
        console.log("Failed to update service status details.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    console.log("Updated Form State:", form); // Debug form changes
  }, [form]);

  const handleDropdownChange = (value, name) => {
    setForm((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: value?.text || "", // Fallback to an empty string
      };
      return updatedForm;
    });
  };

  const handleDateChange = (date, name) => {
    setForm({ ...form, [name]: date });
  };

  const handleValueChange = (value, name) => {
    setForm((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: value,
      };
      return updatedForm;
    });
  };

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSubmit = () => {
    let isValid = true;
    if (reasonRef.current && !reasonRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      serviceStartDateRef.current &&
      !serviceStartDateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      serviceEndDateRef.current &&
      !serviceEndDateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      additionalNotesRef.current &&
      !additionalNotesRef.current.handleValidation()
    ) {
      isValid = false;
    }

    if (isValid) {
      putServiceStatusUpdate(form);
    } else {
      console.log("Form is invalid. Please correct the errors and try again.");
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "50%", // Set your desired width here
          maxWidth: "700px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <img
              style={{
                width: "10%",
                height: "auto",
              }}
              src={EndofService}
              alt="End Of Service"
            />
            <Typography variant="h3">End Of Service</Typography>
          </Box>

          <Box>
            <Grid container direction="row" alignItems="Top" spacing={2}>
              <Grid item xs={12}>
                <Dropdown
                  ref={reasonRef}
                  name="reason"
                  label="Reason"
                  list={list}
                  preFilledvalue={form?.reason}
                  onDropdownSelect={(selectedItem) =>
                    handleDropdownChange(selectedItem, "reason")
                  }
                  required
                ></Dropdown>
              </Grid>
              <Grid item xs={6}>
                <Calendar
                  ref={serviceStartDateRef}
                  name="service_start_date"
                  label="Service Start Date"
                  onDateChange={handleDateChange}
                  preFilledvalue={form?.service_start_date}
                  required
                ></Calendar>
              </Grid>
              <Grid item xs={6}>
                <Calendar
                  ref={serviceEndDateRef}
                  name="service_end_date"
                  label="Service End Date"
                  onDateChange={handleDateChange}
                  preFilledvalue={form?.service_end_date}
                  minDate={form?.service_start_date}
                ></Calendar>
              </Grid>
              <Grid item xs={12}>
                <Name
                  ref={additionalNotesRef}
                  name="notes"
                  className="multiline-textfield"
                  label="Additional Notes"
                  list={list}
                  multiline
                  rows={4}
                  onValueChange={handleValueChange}
                  preFilledvalue={form?.notes}
                  required
                ></Name>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button variant="customsave" onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
