import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg"

function BodyMapView({ bodyPartNames, bodyImages }) {
  const parts = bodyImages[0].split(",");
  const base64Images = parts.filter(
    (_, index) => index % 2 !== 0 && parts[index].trim() !== ""
  );
  return (
    <Box sx={{display: "flex", flexWrap: "wrap",alignItems: "center",justifyContent: "center",textAlign: "center",gap:'20px',pb:3}}>
      {base64Images.map((base64Data, index) => {
        return (
          <Box key={index} sx={{pt:1,position:'relative'}}>
             <IconButton
          aria-label="close"
          // onClick={closeEditMedicationForm}
          sx={{
            position: "absolute",
            // right: 1,
            // top: 8, 
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
             </IconButton>

            <img
              key={index}
              src={`data:image/png;base64,${base64Data}`} // Render the Base64 string as an image
              alt={`Image ${index}`}
              style={{ maxWidth: "100%", height: "auto",  }}
            />
            <Typography variant="h6"  sx={{pt:1,fontFamily: "Arvo",fontWeight:700}}>{bodyPartNames[index]}</Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default BodyMapView;
