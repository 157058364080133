import React, { useState, useEffect } from "react";
import { ClientSideBarHeader } from "../clientReusableComponents/ClientSideBarHeader";
import { Box, Grid, Button } from "@mui/material";
import { getCookie } from "../../../utils/utils";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Admin from "../../../assets/images/users/Admin.svg";

const Tab = ({
  each,
  handleTabSwitch,
  isActiveTab = false,
  setHasUnsavedChanges,
  hasUnsavedChanges,
}) => {
  const [isHover, setHover] = useState(false);

  return (
    <Button
      onClick={() => handleTabSwitch(each.id)}
      key={each.id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variant="outlinedSecondary"
      style={{
        border: isActiveTab
          ? "2px solid #279989"
          : isHover
          ? "2px solid #279989"
          : "2px solid rgba(39, 153, 137, 0.30)",
        color: isActiveTab ? "#279989" : isHover ? "#279989" : "#707171",
        background: each.id === 0 ? "#f0fcfa" : "white",
        borderRadius: each.id === 0 ? "8px" : "5px",
        width: "100%",
        margin: "5px 0px",
        marginBottom: each.id === 0 ? "20px" : "",
        display: "flex",
        justifyContent: "start",
        "&:hover": {
          background: "white",
          color: "#279989",
        },
      }}
    >
      {each.id === 0 ? (
        "+"
      ) : (
        <svg
          width="7"
          height="14"
          viewBox="0 0 7 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1.5L6 7L1 12.5"
            stroke={isHover ? "#279989" : "#969999"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
      <span style={{ margin: "auto" }}>{each.occupation_type}</span>
    </Button>
  );
};

export const MyCareNetworkSideBar = ({
  clientId,
  clientMainData,
  setTabId,
  activeTabID,
  setHasUnsavedChanges,
  hasUnsavedChanges,
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [mcnTabs, setMcnTabs] = useState([]);
  const [pendingTabId, setPendingTabId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPendingTabId(null);
  };

  const handleTabSwitch = (tabId) => {
    if (hasUnsavedChanges) {
      setPendingTabId(tabId);
      handleOpenDialog();
    } else {
      setActiveTab(tabId);
      setTabId(tabId);
    }
  };

  const confirmSwitchTab = () => {
    setActiveTab(pendingTabId);
    setTabId(pendingTabId);
    setHasUnsavedChanges(false);
    handleCloseDialog();
  };

  useEffect(() => {
    const fetchNetwork = async () => {
      if (!clientId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/care-network-get-all/${clientId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          const info = data.data;
          setMcnTabs(info);
          if (activeTab === "") {
            setActiveTab(info[0].id);
            setTabId(info[0].id);
          } else {
            const active = info.filter((f) => f.id === activeTab)[0];
            setActiveTab(active.id);
            setTabId(active.id);
          }
        } else {
          setMcnTabs([]);
          setActiveTab(0);
          setTabId(0);
        }
      } catch (e) {
        console.error("error", e.message);
      }
    };
    fetchNetwork();
  }, [clientId, activeTab]);

  return (
    <Box
      sx={{
        background: "#F0FCFA",
        borderRadius: "8px",
        padding: "10px",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <ClientSideBarHeader clientMainData={clientMainData} />
      <Grid container fullWidth sx={{ width: { xs: "100%" }, gap: 1 }}>
        <Tab
          each={{ id: 0, occupation_type: "Add Care Network" }}
          handleTabSwitch={handleTabSwitch}
          isActiveTab={activeTab === 0}
          setHasUnsavedChanges={setHasUnsavedChanges}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        {mcnTabs.map((each) => (
          <Tab
            key={each.id}
            each={each}
            handleTabSwitch={handleTabSwitch}
            isActiveTab={activeTab === each.id}
            setHasUnsavedChanges={setHasUnsavedChanges}
            hasUnsavedChanges={hasUnsavedChanges}
          />
        ))}
      </Grid>
      <ConfirmationDialog
        openDialog={openDialog}
        TitleText="Do you want to leave without saving changes?"
        paraText="Any unsaved changes will be lost."
        IconUrl={Admin}
        cancelText="Cancel"
        confirmText="Confirm"
        onCancelFun={handleCloseDialog}
        onConfirmFun={confirmSwitchTab}
        handleCloseDialog={handleCloseDialog}
      />
    </Box>
  );
};
