import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
  Grid,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "../../assets/icons/close.svg";
import CloseHoverIcon from "../../assets/icons/closehover.svg";
import Checkbox from "@mui/material/Checkbox";
import User from "../../assets/icons/User.svg";
import { getCookie } from "../../utils/utils";
import Paper from "@mui/material/Paper";
import Car from "../../assets/images/userTableIcons/UserTableCar.svg";
import { BpIcon } from "../reusableComponents/StyledCheckbox";
import { BpCheckedIcon } from "../reusableComponents/StyledCheckbox";

export default function RosterAssignMultivisitCarerDialog({
  openDialog,
  visitsWithMaxCarers,
  visitsWithLessCarers,
  carersList,
  handlestep,
  handleCloseDialog,
  handleMCloseDialog = () => {},
}) {
  const [visits, setVisits] = useState(visitsWithLessCarers);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Initially disable the button
  const [step, setStep] = useState(0);
  const onConfirmFun = () => {
    // console.log("specialvisits", visits);
    postAssignedCarers();
    handleMCloseDialog();
    handleCloseDialog();
  };
  const onCancelFun = () => {
    handleMCloseDialog();
    handleCloseDialog();
  };

  const postAssignedCarers = async () => {
    try {
      const ids = visits.map((item) => ({ [item.visitId]: item.userid }));

      console.log("ids", ids);
      console.log("visits", visits);
      const body = {
        ids: visits.map((item) => ({ [item.visitId]: item.userid })),
      };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-run-assign-users`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      console.log("body", body); // This will log an array of visitid's
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        //setSelectedUser([]);
        handlestep();
      } else {
        console.log("Failed to set assigned carers");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleMClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleMCloseDialog();
    handleCloseDialog();
  };
  const RemoveChangedUsers = () => {
    const carerUserIds = carersList.map((carer) => carer.userid);
    const filteredVisits = visits.map((visit) => ({
      ...visit,
      userid: visit.userid.filter((userid) => carerUserIds.includes(userid)),
    }));
    console.log("filteredVisits", filteredVisits);
    console.log("Visits", visits);
    // Update the visits state with the filtered visits
    setVisits(filteredVisits);
    //setStep(2);
  };
  const handleMergeVisits = () => {
    setVisits((prevVisits) => {
      // Step 1: Get all carer userIds from carersList
      const carerUserIds = carersList.map((carer) => carer.userid);

      // Step 2: Merge visitsWithMaxCarers and add carer userIds to each visit
      const mergedVisits = [
        ...prevVisits, // Previous visits (visits with less carers or updated with checkbox)
        ...visitsWithMaxCarers.map((visit) => {
          // Add all carer userIds to the current visit
          const updatedUserIds = [
            ...new Set([...visit.userid, ...carerUserIds]),
          ];

          // Return the updated visit object with merged userIds
          return { ...visit, userid: updatedUserIds };
        }),
      ];
      console.log("mergedVisits", mergedVisits);

      // Return the updated visits state
      return mergedVisits;
    });
    setStep(1);
  };

  const handleCheckboxChange = (event, userId, visitId) => {
    setVisits((prev) => {
      return prev.map((visit) => {
        // Check if this is the correct visit to update based on visitId
        if (visit.visitId === visitId) {
          // Toggle the userId in the userIds array
          const updatedUserIds = event.target.checked
            ? [...visit.userid, userId] // Add userId if checked
            : visit.userid.filter((id) => id !== userId); // Remove userId if unchecked

          // Return updated visit object
          return { ...visit, userid: updatedUserIds };
        }

        // Return other visits unchanged
        return visit;
      });
    });
  };

  useEffect(() => {
    // Check if all visits satisfy the condition
    const allConditionsMet = visits.every((visit) => {
      // console.log(
      //   "visit.numberOfCarersRequired",
      //   visit.numberOfCarersRequired,
      //   "visit.userid.length",
      //   visit.userid.length,
      //   "Userids",
      //   visit.userid
      // );
      return visit.numberOfCarersRequired === visit.userid.length;
    });

    //console.log("allConditionsMet", allConditionsMet);

    // Enable the button if all visits meet the condition
    setIsButtonDisabled(!allConditionsMet);
  }, [visits]); // This runs whenever `visits` change

  useEffect(() => {
    console.log("allConditionsMet", isButtonDisabled);
  }, [isButtonDisabled]);
  useEffect(() => {
    //if (step === 0) RemoveChangedUsers();
    handleMergeVisits();

    //RemoveChangedUsers();
  }, []); // Empty dependency array to run only once on page load

  useEffect(() => {
    console.log("visitsMain", visits);
    if (step === 1) RemoveChangedUsers();
  }, [step]); // Empty dependency array to run only once on page load

  return (
    <Dialog
      open={openDialog}
      onClose={handleMClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "80%", // Set your desired width here
          maxWidth: "1200px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleMCloseDialog}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          {/* Header here */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              variant="h3"
              sx={{ margin: 0, padding: 0, textWrap: "nowrap" }}
            >
              Change Users
            </Typography>
          </Box>
          <Grid container direction="column">
            {visits
              .filter(
                (visit) =>
                  !visitsWithMaxCarers.some(
                    (maxVisit) => maxVisit.visitId === visit.visitId
                  )
              )
              .map((visit) => {
                return (
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #279989",
                        borderRadius: "8px",
                        backgroundColor: "#E3F2F0",
                        width: "50%",
                        height: "80px",
                        margin: "20px 0",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ marginBottom: "10px", textAlign: "Left" }}>
                        <Typography variant="body4">
                          {visit.clientName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Box sx={{ width: "70%", textAlign: "Left" }}>
                          <Typography variant="body4">
                            {visit.startTime} - {visit.endTime}
                          </Typography>
                        </Box>
                        <Box sx={{ width: "30%", textAlign: "Right" }}>
                          <Typography variant="body4">
                            {visit.numberOfCarersRequired}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "15%",
                        borderTop: "1px dotted  #269AB9",
                        height: "1px",
                        marginLeft: "1px",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "35%",
                        margin: "20px 0",
                        //paddingLeft: "80px",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        "&::before": {
                          content: '""', // This pseudo-element creates the border
                          position: "absolute",
                          left: 0,
                          top: "16.5px", // Leave 20px space from the top
                          bottom: "16.5px", // Leave 20px space from the bottom
                          width: "1px", // No width, only a vertical border
                          borderLeft: "1px dotted #269AB9", // Dotted line style
                        },
                        paddingLeft: "1.5px",
                        //alignItems: "end",
                      }}
                    >
                      {carersList.map((user) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                flex: "1",
                                borderTop: "1px dotted  #269AB9",
                                height: "1px",
                              }}
                            ></Box>
                            <Box
                              sx={{
                                textAlign: "left",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "250px",
                              }}
                            >
                              <Checkbox
                                color="primary"
                                checked={visit.userid.includes(user.userid)}
                                onChange={(event) =>
                                  handleCheckboxChange(
                                    event,
                                    user.userid,
                                    visit.visitId
                                  )
                                }
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                                disabled={
                                  !visit.userid.includes(user.userid) &&
                                  visit.userid.length >=
                                    visit.numberOfCarersRequired
                                } // Disable if limit reached and this checkbox is not selected
                              />

                              <Typography
                                sx={{
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                  overflow: "hidden", // Hide overflowing text
                                  textOverflow: "ellipsis", // Show ellipsis for overflow
                                  maxWidth: "100%", // Adjust based on container width
                                }}
                                variant="body4"
                              >
                                {user.name}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button
            variant="customsave"
            onClick={onConfirmFun}
            color="primary"
            disabled={isButtonDisabled}
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
