import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Checkbox,
  ListItemText,
  MenuItem,
  Grid,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "../../assets/icons/close.svg";
import CloseHoverIcon from "../../assets/icons/closehover.svg";
import SearchIcon from "@mui/icons-material/Search";
import User from "../../assets/icons/User.svg";
import { getCookie } from "../../utils/utils";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Car from "../../assets/images/userTableIcons/UserTableCar.svg";
import RosterAssignMultivisitCarerDialog from "./RosterAssignMultivisitCarerDialog";
import FilterIcon from "@mui/icons-material/FilterList";
import { BpIcon } from "../reusableComponents/StyledCheckbox";
import { BpCheckedIcon } from "../reusableComponents/StyledCheckbox";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#279989",
    padding: "8px 16px",
    border: "none",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "2px solid #eefefa",
    fontSize: 14,
    padding: "8px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eefefa",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function RosterAssignCarerDialog({
  openDialog,
  svisit,
  visits,
  carersList,
  handlestep,
  handleCloseDialog = () => {},
}) {
  const [visit, setVisit] = useState();
  const [runvisits, setRunvisits] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [filtereddUserList, setFiltereddUserList] = useState([]);

  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState([]);
  const [selectedUserLimit, setSelectedUserLimit] = useState(null);
  const [visitsWithMaxCarers, setVisitsWithMaxCarers] = useState([]);
  const [visitsWithLessCarers, setVisitsWithLessCarers] = useState([]);
  const [step, setStep] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [openAssignMultivisitCarerDialog, setOpenAssignMultivisitCarerDialog] =
    useState(false);

  const genderDropdownRef = useRef(null); // Reference to the dropdown container

  const [openGenderFilter, setOpenGenderFilter] = useState(false);

  const handleFilterClick = () => {
    setOpenGenderFilter((prev) => !prev); // Toggle open/close on click
  };

  //AssignMultivisitCarerDialog;

  //visit.numberOfCarersRequired ? visit.numberOfCarersRequired : null;
  const selectvisit = () => {
    if (svisit.runid === "") {
      setVisit(svisit);
    } else {
      const srunvisits = visits.filter(
        (evisit) => evisit.runid === svisit.runid
      );
      setRunvisits(srunvisits);
      const maxVisit = srunvisits.reduce(
        (max, visit) => {
          return visit.numberOfCarersRequired > max.numberOfCarersRequired
            ? visit
            : max;
        },
        { numberOfCarersRequired: 0 }
      ); // Initialize with a very low value

      const maxNumberOfCarersRequired = maxVisit.numberOfCarersRequired;
      const visitsWithMaxCarers = srunvisits.filter(
        (evisit) => evisit.numberOfCarersRequired === maxNumberOfCarersRequired
      );
      const visitsWithLessCarers = srunvisits.filter(
        (evisit) => evisit.numberOfCarersRequired != maxNumberOfCarersRequired
      );

      setVisit(maxVisit);
      setVisitsWithLessCarers(visitsWithLessCarers);
      setVisitsWithMaxCarers(visitsWithMaxCarers);
      console.log("visitsWithMaxCarers", visitsWithMaxCarers);
      console.log("visitsWithlessCarers", visitsWithLessCarers);
    }
    setStep(1);
  };
  const noofcarers = () => {
    setSelectedUserLimit(visit.numberOfCarersRequired);
    console.log("setSelectedUserLimit", selectedUserLimit);
    setStep(2);
  };

  useEffect(() => {
    if (step === 0) selectvisit();
    if (step === 1) noofcarers();
    if (step === 2) console.log("runvisits", runvisits);
  }, [step]);
  useEffect(() => {
    setUsersList(carersList || []); // Fallback to an empty array
    setFilteredUserList(carersList || []);
    //setFiltereddUserList(carersList || []);
    //console.log("Users", carersList);
  }, [carersList]);

  // useEffect(() => {
  //   const filteredlist = filteredUserList.filter((user) =>
  //     selectedGender.includes(user.gender)
  //   );
  //   setFiltereddUserList(filteredlist);
  //   //console.log("Users", carersList);
  // }, [selectedGender]);

  useEffect(() => {
    setSelectedUser(visit?.userid || []);
  }, [visit?.userid]);

  const uniqueGender = Array.from(
    new Set(usersList.map((user) => user.gender))
  );

  const [selectedGender, setSelectedGender] = useState(uniqueGender);

  // Handle checkbox changes
  const handleGenderFilterCheckbox = (gender) => (event) => {
    const updatedSelectedGender = event.target.checked
      ? [...selectedGender, gender] // Add selected gender
      : selectedGender.filter((g) => g !== gender); // Remove unselected gender

    setSelectedGender(updatedSelectedGender);
    // Apply updated filters
    applyFilters(updatedSelectedGender, searchValue);
    //console.log("selectedGender", selectedGender);
  };

  const postAssignedCarers = async () => {
    try {
      const runid = visit.runid;
      const visitsWithRunId =
        runid != ""
          ? visits
              .filter((visit) => visit.runid === runid) // Filter visits with non-empty runid
              .map((visit) => visit.visitId)
          : ""; // Extract the visitid from each filtered visit
      const user_id = selectedUser.map((user) => user);

      console.log("visitrunid cehck visit", visitsWithRunId); // This will log an array of visitid's

      const body = {
        ids:
          visit.runid === ""
            ? [{ [visit.visitId]: user_id }]
            : visitsWithRunId.map((visitId) => ({
                [visitId]: user_id,
              })),
      };

      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-run-assign-users`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      console.log("body", body); // This will log an array of visitid's
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        setSelectedUser([]);
        handlestep();
      } else {
        console.log("Failed to set assigned carers");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleCheckboxChange = (event, userid) => {
    setSelectedUser((prev) => {
      // Toggle the userId in the selectedUsers array based on whether the checkbox is checked or not
      const selectedUser = event.target.checked
        ? [...prev, userid] // Add userId if checked
        : prev.filter((id) => id !== userid); // Remove userId if unchecked
      return selectedUser;
    });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value); // Update the input's state

    // Apply updated filters
    applyFilters(selectedGender, value);
  };
  const handleCloseAssignMultivisitCarerDialog = () => {
    setOpenAssignMultivisitCarerDialog(false);
  };

  // Apply filters: gender + search
  const applyFilters = (genderFilters, searchQuery) => {
    let filteredData = usersList;

    // Filter by gender
    if (genderFilters.length > 0) {
      console.log("genderFilters", genderFilters);
      filteredData = filteredData.filter((user) =>
        genderFilters.includes(user.gender)
      );
      console.log("filteredData", filteredData);
    }

    // Filter by search query
    if (searchQuery) {
      console.log("searchQuery", searchQuery);
      filteredData = filteredData.filter((user) =>
        user.name.toLowerCase().includes(searchQuery)
      );
      console.log("filteredData", filteredData);
    }

    setFilteredUserList(filteredData);
  };

  // useEffect(() => {
  //   console.log("Selected User:", selectedUser);
  // }, [selectedUser]);

  const onConfirmFun = () => {
    const filteredUsers = usersList.filter((user) =>
      selectedUser.includes(user.userid)
    );
    setSelectedUserDetails(filteredUsers);
    if (visitsWithLessCarers.length > 0) {
      setOpenAssignMultivisitCarerDialog(true);
    } else {
      postAssignedCarers();
      handleCloseDialog();
    }

    //console.log("selectedUser", selectedUserDetails);
  };
  const onCancelFun = () => {
    handleCloseDialog();
  };

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "80%", // Set your desired width here
          maxWidth: "1200px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          {/* Header here */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Left Header Section */}
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "25px", marginRight: "10px" }}
                src={User}
                alt="User"
              />
              {selectedUser.length > 0 ? (
                <Typography
                  variant="h3"
                  sx={{ margin: 0, padding: 0, textWrap: "nowrap" }}
                >
                  Change Users
                </Typography>
              ) : (
                <Typography
                  variant="h3"
                  sx={{ margin: 0, padding: 0, textWrap: "nowrap" }}
                >
                  Assign Users
                </Typography>
              )}
            </Box>
            {/* Right Header Section */}
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                value={searchValue} // Controlled value
                onChange={handleSearchChange}
                size="small"
                placeholder="Search Users"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ margin: 0, padding: 0 }}
                    >
                      <SearchIcon
                        sx={{
                          color: "#279989",
                          width: "20px",
                          marginLeft: "10px",
                          marginTop: "2px",
                          marginRight: "2px",
                          padding: 0,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{ minWidth: "250px" }}
              ></TextField>
              <Typography
                variant="h3"
                sx={{
                  margin: 0,
                  padding: 0,
                  textWrap: "nowrap",
                  width: "250px",
                }}
              >
                {selectedUser.length}/{selectedUserLimit} Users Selected
              </Typography>
            </Box>
          </Box>
          {/* Table Content */}
          <Box sx={{ marginTop: "20px" }}>
            <TableContainer
              component={Paper}
              sx={{ height: "370px", overflow: "auto" }}
            >
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Name
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Contact
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Gender
                        <FilterIcon
                          ref={genderDropdownRef} // Attach the ref to the dropdown box
                          onClick={handleFilterClick}
                          sx={{
                            cursor: "pointer",
                            width: "20px",
                            position: "relative",
                            marginLeft: "5px",
                          }}
                        />
                      </Typography>
                      {openGenderFilter && (
                        <Box
                          ref={genderDropdownRef}
                          sx={{
                            position: "absolute", // Ensures menu appears exactly below
                            border: "1px solid #cef4f4",
                            top: "40px", // Places it right below the header
                            right: "20px", // Aligns with the "gender" header
                            zIndex: 20, // Ensures it's above other elements
                            backgroundColor: "white",
                            borderRadius: "4px",
                            width: "140px", // Adjust width as needed
                            maxHeight: "200px",
                            overflowY: "auto",
                          }}
                        >
                          {uniqueGender.map((gender) => (
                            <MenuItem
                              key={gender}
                              value={gender}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "8px",
                                height: "40px",
                                scroll: "auto",
                                margin: "0px",
                                cursor: "pointer", // Ensures the pointer changes to a hand
                                "&:hover": {
                                  backgroundColor: "#f0f0f0", // Optional: Highlight on hover
                                },
                              }}
                            >
                              <Checkbox
                                checked={selectedGender.includes(gender)}
                                onChange={handleGenderFilterCheckbox(gender)}
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                              />
                              <ListItemText
                                primary={gender ? gender : "N/A"}
                                sx={{
                                  marginLeft: "-15px",
                                  whiteSpace: "nowrap", // Prevents wrapping
                                  overflow: "hidden", // Hides overflowed content
                                  textOverflow: "ellipsis", // Adds ellipsis for overflowed text
                                }}
                              />
                            </MenuItem>
                          ))}
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Admin
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                          width: "80%",
                        }}
                      >
                        Status
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {filteredUserList.length === 0 && (
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography fullwidth variant="body4">
                        No Users Found{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                )}
                <TableBody sx={{ overflow: "auto" }}>
                  {filteredUserList
                    .filter((user) => user.status === 1)
                    .map((user, index) => (
                      <StyledTableRow key={user.userid}>
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedUser.includes(user.userid)}
                            onChange={(event) =>
                              handleCheckboxChange(event, user.userid)
                            }
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            disabled={
                              !selectedUser.includes(user.userid) &&
                              selectedUser.length >= selectedUserLimit
                            } // Disable if limit reached and this checkbox is not selected
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{
                            maxWidth: "150px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                              textWrap: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {user.name}
                            {user.drive == 0 ? (
                              <img
                                src={Car}
                                alt="Car"
                                style={{
                                  marginLeft: "10px",
                                  verticalAlign: "middle",
                                }}
                              />
                            ) : null}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                            }}
                          >
                            {user.contact_number}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                            }}
                          >
                            {user.gender}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                            }}
                          >
                            {user.admin == "1" ? "Yes" : "No"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                              border:
                                user.status == "1"
                                  ? "1px solid #03AE71"
                                  : "1px solid #F5891D", // Change border color based on status
                              borderRadius: "8px",
                              backgroundColor:
                                user.status == "1" ? "#F1FFF3" : "#FFF0E3",
                              padding: "4px",
                              color: user.status == "1" ? "#03AE71" : "#F5891D",
                              width: "80%",
                            }}
                          >
                            {user.status == "1" ? "Active" : "Inactive"}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button
            variant="customsave"
            onClick={onConfirmFun}
            color="primary"
            disabled={selectedUser.length !== selectedUserLimit}
          >
            Save
          </Button>
        </DialogActions>
        {openAssignMultivisitCarerDialog && (
          <RosterAssignMultivisitCarerDialog
            openDialog={openAssignMultivisitCarerDialog}
            handleMCloseDialog={() => {
              handleCloseAssignMultivisitCarerDialog();
            }}
            visitsWithMaxCarers={visitsWithMaxCarers}
            visitsWithLessCarers={visitsWithLessCarers}
            carersList={selectedUserDetails}
            handlestep={handlestep}
            handleCloseDialog={handleCloseDialog}
          />
        )}
      </Box>
    </Dialog>
  );
}
