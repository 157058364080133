import React, { useState } from "react";
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import GetAppIcon from '@mui/icons-material/GetApp';
import usericon from '../../assets/images/reports/usersicon.png';
import clienticon from '../../assets/images/reports/clientsicon.png';
import visiticon from '../../assets/images/reports/visitsicon.png';
import todoicon from '../../assets/images/reports/todosicon.png';
import medicationicon from '../../assets/images/reports/medicationIcon.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import alerticon from '../../assets/images/reports/alerticon.png';
import UserReports from "./users/UserReports";
import MilesTravelled from "./users/MilesTravelled";
import TimeSpentOnTravel from "./users/TimeSpentOnTravel";
import ClientsOverview from "./clients/ClientsOverview";
import CareAssessments from "./clients/CareAssessments";
import ClientTimeSpentOnTravel from "./clients/ClientTimeSpentOnTravel";
import CareHoursLogged from "./visits/CareHoursLogged";
import VisitTimeLine from "./visits/VisitTimeLine";
import ListOfTodos from "./todos/ListOfTodos";
import SubMittedTodos from "./todos/SubMittedTodos";
import ListOfMedications from "./medication/ListofMedication";
import SubMittedMedication from "./medication/SubMittedMedication";
import AlertsRaisedandResolved from "./alerts/AlertsRaisedandResolved";




function ReportsMain() {
  const [selectedPage, setSelectedPage] = useState("Users");
  const [expandedMenu, setExpandedMenu] = useState("Users"); // Track expanded menu sections
  const [selectedSubPage, setSelectedSubPage] = useState('Users Overview'); // Track selected subpage
  
  // Menu items grouped by headings with submenus
  const menuSections = [
    {
      heading: "Reports List",
      items: [
        {
          name: "Users",
          icon: usericon,
          subPages: ["Users Overview", "Miles Travelled", "Time Spent on Travel"],
        },
        {
          name: "Clients",
          icon: clienticon,
          subPages: ["Clients Overview", "Care Assessments", "Time spent on travel"],
        },
      ],
    },
    {
      heading: "Care Management",
      items: [
        {
          name: "Visits",
          icon: visiticon,
          subPages: ["Care Hours Logged", "Visit Timeliness"],
        },
        {
          name: "To-Do's",
          icon: todoicon,
          subPages: ["List of Todos", "Submited Todos"],
        },
        {
          name: "Medication",
          icon: medicationicon,
          subPages: ["List of Medications", "Submitted Medications"],
        },
      ],
    },
    {
      heading: "Others",
      items: [
        {
          name: "Alert Response",
          icon: alerticon,
          subPages: ["Alerts raised & resolved"],
        },
      ],
    },
  ];
  
  // Render selected page component
  const renderContent = () => {
    if (selectedSubPage) {
      switch (selectedSubPage) {
        case "Users Overview":
          return <UserReports />;
        case "Miles Travelled":
          return <MilesTravelled />;
        case "Time Spent on Travel":
          return <TimeSpentOnTravel />;
        case "Clients Overview":
          return <ClientsOverview />;
        case "Care Assessments":
          return <CareAssessments />;
        case "Time spent on travel":
          return <ClientTimeSpentOnTravel />;
        case "Care Hours Logged":
          return <CareHoursLogged />;
        case "Visit Timeliness":
          return <VisitTimeLine />;
        case "List of Todos":
          return <ListOfTodos />;
        case "Submited Todos":
          return <SubMittedTodos />;
        case "List of Medications":
          return <ListOfMedications />;
        case "Submitted Medications":
          return <SubMittedMedication />;
        case "Alerts raised & resolved":
          return <AlertsRaisedandResolved />;
        default:
          return <Typography>Select a subpage</Typography>;
      }
    }
  
    return <Typography>Select a page</Typography>;
  };

  return (
    <Box sx={{ display: "flex",gap:'1px', width: "96%",backgroundColor:'#F0FCFA', margin:"0 auto" , overflowY:'hidden'}}>
    
      {/* Sidebar */}
      <Box
       sx={{
        width: "20%",
        bgcolor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        borderTopLeftRadius: '10px', // Top-left corner
        borderBottomLeftRadius: '10px',
        overflowY: "auto",
        height: "calc(100vh - 100px)",
        padding: 1,
        marginTop: "20px",
    
      }}
    >
      <Box sx={{ mt: 3 }}>
        {menuSections.map((section) => (
          <Box key={section.heading} sx={{ mb: 3 }}>
            {/* Section Heading */}
            <Typography
              sx={{
                fontWeight: "600",
                textAlign: "left",
                textTransform: "uppercase",
                color: "#279989",
                fontSize: "14px",
              }}
            >
              {section.heading}
            </Typography>

            {/* Menu Items */}
            <List>
              {section.items.map((item) => (
                <React.Fragment key={item.name}>
                  <ListItem
                    button
                    onClick={() =>
                      setExpandedMenu(expandedMenu === item.name ? null : item.name)
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "6px 14px",
                      borderRadius: 2,
                      // bgcolor: expandedMenu === item.name ? "#d1e9ff" : "transparent",
                      // ":hover": { bgcolor: "#e3f2fd" },
                    }}
                  >
                    {/* Left Icon and Text */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img src={item.icon} alt={item.name} style={{ width: "22px", height: "22px" }} />
                      <ListItemText>
                        <Typography sx={{ fontSize: "15px", color: "#000000" }}>{item.name}</Typography>
                      </ListItemText>
                    </Box>

                    {/* Arrow Icon */}
                    {expandedMenu === item.name ? (
                      <ExpandMoreIcon sx={{ color: "#000000" }} />
                    ) : (
                      <NavigateNextIcon sx={{ color: "#000000" }} />
                    )}
                  </ListItem>
                  {/* Submenu */}
                  {expandedMenu === item.name && item.subPages && (
                    <List sx={{ ml: 4,mr:5, borderLeft: "2px solid #279989" ,}}>
                      {item.subPages.map((subPage) => (
                        <ListItem
                          button
                          key={subPage}
                          onClick={() => {
                            setSelectedPage(item.name);
                            setSelectedSubPage(subPage);
                          }}
                          sx={{

                           ml: 2,
                            borderRadius: 2,
                            bgcolor: selectedSubPage === subPage ? "#279989" : "transparent",
                            
                            ":hover": { bgcolor: selectedSubPage === subPage ? "#279989" : "#e3f2fd" },//"#e3f2fd"
                          }}
                        >
                          <Typography sx={{ fontSize: "14px",color: selectedSubPage === subPage ? "white" : "#000000",fontWeight: selectedSubPage === subPage ? "bold" : "normal",padding:'1px'  }}>
                            {subPage}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </React.Fragment>
              ))}
            </List>
          </Box>
        ))}
      </Box>

    
    </Box>

      {/* Main Content Area */}
      <Box
        sx={{
       width: '80%',
       marginTop:"20px",
       borderTopRightRadius: '10px', // Top-left corner
       borderBottomRightRadius: '10px', 
       backgroundColor:'#FFFFFF',
      
      //  boxShadow: '0px 0px 5px 5px rgba(39, 153, 137, 0.2)',
      
        }}
      >
        <Box >
        {renderContent()}
        </Box>
      </Box>
    
    </Box>
  );
}

export default ReportsMain;
