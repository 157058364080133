import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import { getCookie } from "../../utils/utils";

// Function to convert kilometers to miles
const kmToMiles = (km) => {
  return (km * 0.621371).toFixed(2); // Convert km to miles and round to 2 decimal places
};

const DistanceMatrix = ({ origin, destination }) => {
  const [distance, setDistance] = useState(null);
  const [time, setTime] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (origin && destination) {
      fetchDistanceMatrix();
    }
  }, [origin, destination]);

  const fetchDistanceMatrix = async () => {
    // Reset any previous data or errors
    setDistance(null);
    setTime(null);
    setError("");

    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/distancematrix?origins=${origin}&destinations=${destination}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (data.status === "OK" && data.rows.length > 0) {
        const element = data.rows[0].elements[0];

        // Convert distance from km to miles
        const distanceInKm = element.distance.value / 1000; // Google Maps returns distance in meters, so divide by 1000 to get km
        const distanceInMiles = kmToMiles(distanceInKm);

        setDistance(`${distanceInMiles} miles`); // Set converted distance
        setTime(element.duration.text); // Duration in text (e.g., "3 mins")
      } else {
        setError("Failed to retrieve distance and time.");
      }
    } catch (error) {
      setError("Error fetching data: " + error.message);
    }
  };

  if (!origin || !destination) {
    return <p>Please provide both origin and destination.</p>;
  }

  return (
    <div>
      {error && (
        // <p style={{ color: "red", marginTop: "20px" }}>
        //   <strong>{error}</strong>
        // </p>
        <Typography variant="body4" sx={{ color: "red" }}>
          Invalid Postcode
        </Typography>
      )}

      {distance && time && (
        <div>
          <Typography variant="body4">{distance},&nbsp;</Typography>
          <Typography variant="body4">{time}</Typography>
        </div>
      )}
    </div>
  );
};

export default DistanceMatrix;
