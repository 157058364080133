import React, { useEffect, useState } from 'react'
import { getCookie } from '../../../utils/utils';
import useStyles from '../../clients/styles/useStyles';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Paper, Typography } from '@mui/material';
import CustomButtom from '../../reusableComponents/CustomButton';
import dayjs from "dayjs";
import CareboxLogo from "../../../assets/images/users/logo.jpg";
import agencyLogo from "../../../assets/images/users/agency2.jpg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CustomCalendar from '../../logs/CustomCalendar';
const doc = new jsPDF();
const paginationModel = { page: 0, pageSize: 20 };
const columns = [
  {field:"clientName",headerName: 'Client Name', headerAlign: 'center', align: 'center'},
  { field: 'userName', headerName: 'User Name',  headerAlign: 'center', align: 'center'},
  { field: 'visit_date', headerName: 'Visit Date',  headerAlign: 'center', align: 'center'},
  { field: 'sessionType', headerName: 'Session',  headerAlign: 'center', align: 'center'},
  { field: 'plannedStartTime', headerName: 'Planned Starttime',  headerAlign: 'center', align: 'center'},
  { field: 'plannedEndTime', headerName: 'Planned Endtime',  headerAlign: 'center', align: 'center'},
]
function VisitTimeLine() {
  const [careHousLogged, setcareHousLogged] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const classes = useStyles()
  // useEffect(()=>{
  //   const fetchUsersAPI = async () => {
  //     try {
  //       const token = getCookie();
  //       //https://3.8.158.142:8443/api
  //       const URL = `${
  //         process.env.REACT_APP_BASE_URL
  //       }/getVisitsTimeDetails?fromDate=${dayjs(startDate).format(
  //         "YYYY-MM-DD"
  //       )}&toDate=${dayjs(endDate).format("YYYY-MM-DD")}`;
        
  //       const options = {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       };
  //       const response = await fetch(URL, options);
  //       const data = await response.json();
  //     console.log(data.data,'==============================================cleients overview');
  
  //       
      
  //       if (response.ok === true) {
  //         setcareHousLogged(data.data);
  //       } else {
  //         console.log("get all failed");  
  //         setcareHousLogged([]);
  //       }
  //     } catch (e) {
  //       console.log("error", e.message);
  //     }
  //   };
  //   fetchUsersAPI();
  // },[startDate,endDate])
  const fetchUsersAPI = async (fromDate, toDate) => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/getVisitsTimeDetails?fromDate=${fromDate}&toDate=${toDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await fetch(URL, options);
      const data = await response.json();
  
      if (response.ok) {
        setcareHousLogged(data.data);
      } else {
        console.error("API call failed");
        setcareHousLogged([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
  };
  // Fetch data when the component mounts (initial load)
  useEffect(() => {
    const initialStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const initialEndDate = dayjs(endDate).format("YYYY-MM-DD");
    fetchUsersAPI(initialStartDate, initialEndDate);
  }, []); // Empty dependency array ensures this runs only on the first render
  
  // Fetch data when startDate or endDate changes
  useEffect(() => {
    const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");
  
    fetchUsersAPI(updatedStartDate, updatedEndDate);
  }, [startDate, endDate]);
  const rows = careHousLogged.map((item) => {
    // Clean up the userName string to remove square brackets and split names by commas if needed
    const cleanedUserNames = item.userName
      .replace(/[^\w\s,]/g, '') // Remove square brackets or any unwanted characters
      .split(',') // Split into individual names by comma
      .map(name => name.trim()) // Trim any leading/trailing spaces from names
       // Format the visit_date to "date-month-year"
  const formattedVisitDate = dayjs(item.visit_date).format("DD-MM-YYYY");
  
    return {
      id: item.visitdetailsid, 
      clientName:item.clientName,
      userName: cleanedUserNames.join(', '),  // Join the cleaned names back with a comma
      visit_date:formattedVisitDate,
      sessionType: item.sessionType,
      plannedStartTime: item.plannedStartTime,
      plannedEndTime: item.plannedEndTime
    }
  });
  const adjustedColumns = columns.map((col) => {
    const isAddressColumn = col.field === "userName"; // Check if it's the `userName` column
    return {
      ...col,
      flex: isAddressColumn ? 2 : 1, // Dynamic width for `userName`
      minWidth: isAddressColumn ? 150 : 90, // Ensure minimum width for each column
      renderCell: (params) => (
        <Box className={classes.gridColumns}
        >
          {params.value || "N/A"} {/* Display placeholder if value is empty */}
        </Box>
      ),
    };
  });
  const exportClientsPdf = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableMargin = 3; // Margin for the table on left and right
    const imageHeight = 13; // Fixed height for the images
    const imageYPosition = 10; // Fixed vertical position for the images
    const logoWidthLeft = 33; // Width for the left logo
    const logoWidthRight = 33; // Width for the right logo
  
    // Add background color
    doc.setFillColor("f5fcff");
    doc.rect(0, 0, pageWidth, doc.internal.pageSize.getHeight(), "F");
  
    // Add left and right logos aligned with the table
    doc.addImage(CareboxLogo, "JPEG", tableMargin, imageYPosition, logoWidthLeft, imageHeight); // Left logo
    doc.addImage(
      agencyLogo,
      "JPEG",
      pageWidth - tableMargin - logoWidthRight,
      imageYPosition,
      logoWidthRight,
      imageHeight
    ); // Right logo
     // Add title
    doc.setFontSize(18);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text("VisitTimeline reports List", pageWidth / 2, imageYPosition + imageHeight / 2, { align: "center" });
    // Add date below the title
    doc.setFontSize(12);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toISOString().slice(0, 10);
    doc.text(`Date: ${today}`, tableMargin, imageYPosition + imageHeight + 5);
    // Data Preparation
    const finalVisitReports = rows?.filter((u) =>
      rowSelectionModel?.includes(u.id)
    );
    if (!finalVisitReports.length) {
      console.error("No data available to display.");
      return;
    }
  
    const body = finalVisitReports?.map((visit) => {
      const cleanedUserNames = visit.userName
      .replace(/[^\w\s,]/g, '') // Remove square brackets or any unwanted characters
      .split(',') // Split into individual names by comma
      .map(name => name.trim()) // Trim any leading/trailing spaces from names
      return [
        visit?.clientName,
        cleanedUserNames.join(', '),
        visit?.visit_date,
        visit?.session,
        visit?.plannedStarttime,
      ];
    });
  
    const clientColumns = [
      "ClientName",
      "UserName",
      "visitDate",
      "Session",
      "PlannedStarttime",
    
    ];
    doc.autoTable({
      head: [clientColumns], // Table header (column titles)
      body: body, // Table body (data rows)
      startY: 34,
      tableWidth: "auto", // Adjusts table width to content
      margin: { top: 10, left: 3, right: 3 }, // Table margin
      alternateRowStyles: { fillColor: "#e4f6f3" },
      columnStyles: {
        0: { cellWidth: "auto", halign: "center" }, 
        1: { cellWidth: "auto", halign: "center" }, 
        2: { cellWidth: "auto", halign: "center" }, 
        3: { cellWidth: "auto", halign: "center" }, 
        4: { cellWidth: "auto", halign: "center" }, 
        
      },
      headStyles: {
        halign: "center", // Center-align header cells
        valign: "middle", // Vertical alignment for header
        fillColor: "#279989",
        fontStyle: "Courier",
      },
      styles: {
        cellPadding: { top: 5.4, right: 1.5, bottom: 5.4, left: 1.5 },
      },
      didDrawCell: (data) => {
        // if (data.section === "body" && data.column.index === 1) {
        //   const rowIndex = data.row.index;
        //   const med = finalMedications[rowIndex];
    
        //   // Determine days for frequency
        //   const days =
        //     med?.medication_type === "Blister Pack"
        //       ? med?.blister_pack_dayname || []
        //       : med?.medication_type === "PRN"
        //       ? ''
        //       : med?.scheduled_dayname || [];
    
        //   const x = data.cell.x; // Start drawing from the beginning of the cell
        //   const y = data.cell.y + 2; // Base Y position (slightly lower for better alignment)
        //   const boxSize = 6;
        //   const spacing = 1.5;
    
        //   // Draw boxes for each day
        //   if (med?.medication_type !== "PRN"){

        //     sessionsConstants?.forEach((day, index) => {
        //     const isDaySelected = days?.includes(day);
      
        //       // Conditional colors and styles
        //       const borderColor = isDaySelected ? [39, 153, 137] : [39, 153, 137]; // Green border for both cases
        //       const backgroundColor = isDaySelected ? [39, 153, 137] : [255, 255, 255]; // Green for selected, white for unselected
        //       const textColor = isDaySelected ? [255, 255, 255] : [39, 153, 137]; // White text for selected, green for unselected
      
        //       // Draw rounded rectangle (box)
        //       doc.setDrawColor(...borderColor); // Set border color
        //       doc.setFillColor(...backgroundColor); // Set background color
        //       doc.roundedRect(
        //         x + index * (boxSize + spacing), // X position with consistent starting alignment
        //         y, // Y position
        //         boxSize, // Width
        //         boxSize, // Height
        //         1, // Horizontal corner radius
        //         1, // Vertical corner radius
        //         "FD" // Fill and draw
        //       );
      
        //       // Set text properties
        //       doc.setTextColor(...textColor); // Set text color
        //       doc.setFontSize(8);
        //       doc.text(
        //         day.slice(0, 2), // First two letters of the day
        //         x + index * (boxSize + spacing) + boxSize / 2, // Center the text horizontally
        //         y + boxSize / 2 + 1, // Center the text vertically (adjust based on font size)
        //         { align: "center" } // Center alignment
        //       );
  
        //     });
        //   }
        //   const getAdditionalText = (med) => {
        //     const timesTextMap = {
        //       0: "Never",
        //       1: "Once a Day",
        //       2: "Twice a Day",
        //       3: "Thrice a Day",
        //     };
          
        //     if (med?.medication_type === "Blister Pack") {
        //       return timesTextMap[med?.blister_pack_times_per_day] || `${numberToText(med?.blister_pack_times_per_day)} Times a Day`;
        //     } else if (med?.medication_type === "PRN") {
        //       return ""; // No text for PRN
        //     } else {
        //       return timesTextMap[med?.scheduled_times_per_day] || `${numberToText(med?.scheduled_times_per_day)} Times a Day`;
        //     }
        //   };
          
        //   const additionalText = getAdditionalText(med);
          
        //   if (additionalText) {
        //     doc.setFontSize(8);
        //     doc.setTextColor(100, 100, 100);
        //     doc.text(
        //       additionalText, // Text content
        //       x + data.cell.width / 2, // Centered horizontally within the cell
        //       y + boxSize + 4, // Position below the last box (adjust as needed)
        //       { align: "center" } // Center alignment
        //     );
        //   }
        // }
      },
    });   //   // Add page numbers
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    doc.setFillColor("f5fcff");
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        pageWidth / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    }
   // Save the PDF
    
    doc.save(`VisitTimeline_reports_${today}.pdf`);
  };
  
  
  return (
    <Box sx={{ px: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%",px:2,pb:2,alignItems: "center" }}>
        <Box sx={{width:'45%'}}>

<CustomCalendar 
 selectedDate={selectedDate} setSelectedDate={setSelectedDate} 
 startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
</Box>
      <Typography variant="h5"  sx={{p:0,m:0,color:"#279989"}}>
      Users Reports By VisistTimeLine
      </Typography>
      <CustomButtom text="Download" onClick={exportClientsPdf } disabled={rowSelectionModel.length === 0} />
      </Box>
       <Box className={classes.tableMainContainer}>
      <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 30, 40]}
      
          checkboxSelection 
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          getRowHeight={(params) => {
            // Ensure the row data exists before accessing properties
            if (!params.row) {
              return 60; // Default height for undefined or empty rows
            }
            const content = params.row.userName || ""; // Safely access `userName`
            const estimatedLineCount = Math.ceil(content.length / 30); // Estimate lines
            return Math.max(60, estimatedLineCount * 20); // Ensure a minimum height of 50px
          }}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain} />
          </Box>
    </Box>
  )
}

export default VisitTimeLine