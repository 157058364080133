import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Grid, Container } from "@mui/material";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../themes/Theme";
import AddNewRunDialog from "./AddNewRunDialog";
import AddVisitIcon from "../../assets/images/roster/addvisits.svg";
import HoverAddVisitIcon from "../../assets/images/roster/addvisitshover.svg";
import EditRunName from "../../assets/images/roster/editrunname.svg";
import HoverEditRunName from "../../assets/images/roster/editrunnamehover.svg";
import DeleteRun from "../../assets/images/roster/deleterun.svg";
import HoverDeleteRun from "../../assets/images/roster/deleterunhover.svg";
import NoRuns from "../../assets/images/roster/noruns.svg";
import ConfirmationDialog from "../reusableComponents/ConfirmationDialog";
import DeleteConfirmationIcon from "../../assets/images/roster/deleteconfimationicon.svg";
import { Link } from "react-router-dom";
import { getCookie } from "../../utils/utils";

export default function CreateRunForm({
  setOpenManageRunVisitsForm,
  selectedRun,
  setSelectedRun,
}) {
  const [runsList, setRunsList] = useState([]);
  const [openAddNewRunDialog, setOpenAddNewRunDialog] = useState(false);
  //const [selectedRun, setSelectedRun] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const fetchRunsAPI = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-get-all-runs`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok === true) {
        setRunsList(data.data);
      } else {
        console.log("Failed to fetch runs");
        setRunsList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  // Fetch runs on initial render
  useEffect(() => {
    fetchRunsAPI();
  }, [openAddNewRunDialog, openDeleteConfirmation]);

  const deleteRunAPI = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-run/${selectedRun.id}`;
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        setOpenDeleteConfirmation(false);
      } else {
        console.log("Failed to delete NewRun.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDeleteRun = () => {
    deleteRunAPI();
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          //width: { md: "75%", xs: "90%" },
        }}
        maxWidth={false}
      >
        <Box
          sx={{ width: "100%", alignItems: "center", justifyContent: "center" }}
        >
          <Grid container direction="row" alignItems="Top">
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid sx={{ textAlign: "left" }}>
                <Typography variant="h4">Runs</Typography>
                <Typography
                  variant="body1"
                  sx={{ marginLeft: "-20px", marginTop: "-7px" }}
                >
                  Utilise runs to consolidate visits and make the most of your
                  schedule
                </Typography>
              </Grid>
              <Grid>
                <Button
                  variant="outlinedSecondary"
                  onClick={() => {
                    setSelectedRun(null);
                    setOpenAddNewRunDialog(true);
                  }}
                >
                  + Add New Run
                </Button>
                <Link to="/roster" style={{ textDecoration: "none" }}>
                  <Button
                    variant="outlinedSecondary"
                    sx={{ marginLeft: "30px" }}
                  >
                    Back
                  </Button>
                </Link>
              </Grid>
            </Box>
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              backgroundColor: "#279989",
              padding: "8px 16px",
              borderRadius: "8px",
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ color: "white" }}>
                Run Name
              </Typography>
            </Box>
            <Box
              sx={{
                minWidth: "200px",
                textAlign: "center",
              }}
            >
              <Typography variant="h6" sx={{ color: "white" }}>
                Action
              </Typography>
            </Box>
          </Box>
          {runsList.length > 0 ? (
            runsList
              .filter((run) => run.status != "Inactive")
              .map((run) => (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    border: "1px solid #2799894D",
                    padding: "5px 16px",
                    borderRadius: "8px",
                  }}
                >
                  <Box>
                    <Typography variant="h6" sx={{ color: "Black" }}>
                      {run.run_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      minWidth: "150px",
                      textAlign: "center",
                      marginRight: "18px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "33%",
                        flexDirection: "column", // Stack icon and text vertically
                        alignItems: "center", // Center the icon and text
                        cursor: "pointer",
                        position: "relative", // To position the text
                        "&:hover .hoverText": {
                          opacity: 1, // Show text on hover
                        },
                      }}
                    >
                      <img
                        src={AddVisitIcon}
                        alt="AddVisitIcon"
                        style={{
                          width: "22px",
                          height: "22px",
                          filter: "none", // Use filters if you need color change on hover
                          fill: "#279989 ! important",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.src = HoverAddVisitIcon;
                          e.currentTarget.style.cursor = "pointer";
                        }}
                        onMouseOut={(e) => (e.currentTarget.src = AddVisitIcon)}
                        onClick={() => {
                          setSelectedRun(run);
                          setOpenManageRunVisitsForm(true);
                        }}
                      />
                      <Typography
                        variant="caption"
                        className="hoverText"
                        sx={{
                          opacity: 0, // Start hidden
                          fontFamily: "Arvo",
                          transition: "opacity 0.3s", // Smooth transition for text visibility
                          position: "absolute", // Position text directly under the icon
                          bottom: "-28px", // Adjust this value as needed
                          whiteSpace: "nowrap", // Prevent text from wrapping
                          color: "#279989 ! important", // Set the text color as needed
                          textAlign: "center",
                        }}
                      >
                        Add Visits
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "33%",
                        flexDirection: "column", // Stack icon and text vertically
                        alignItems: "center", // Center the icon and text
                        cursor: "pointer",
                        position: "relative", // To position the text
                        "&:hover .hoverText": {
                          opacity: 1, // Show text on hover
                        },
                      }}
                    >
                      <img
                        src={EditRunName}
                        alt="EditRunName"
                        style={{
                          width: "20px",
                          height: "20px",
                          filter: "none", // Use filters if you need color change on hover
                          fill: "#279989 ! important",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.src = HoverEditRunName;
                          e.currentTarget.style.cursor = "pointer";
                        }}
                        onMouseOut={(e) => (e.currentTarget.src = EditRunName)}
                        onClick={() => {
                          setSelectedRun(run);
                          setOpenAddNewRunDialog(true);
                        }}
                      />
                      <Typography
                        variant="caption"
                        className="hoverText"
                        sx={{
                          opacity: 0, // Start hidden
                          fontFamily: "Arvo",
                          transition: "opacity 0.3s", // Smooth transition for text visibility
                          position: "absolute", // Position text directly under the icon
                          bottom: "-28px", // Adjust this value as needed
                          whiteSpace: "nowrap", // Prevent text from wrapping
                          color: "#279989 ! important", // Set the text color as needed
                          textAlign: "center",
                        }}
                      >
                        Edit Run Name
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "33%",
                        flexDirection: "column", // Stack icon and text vertically
                        alignItems: "center", // Center the icon and text
                        cursor: "pointer",
                        position: "relative", // To position the text
                        "&:hover .hoverText": {
                          opacity: 1, // Show text on hover
                        },
                      }}
                    >
                      <img
                        src={DeleteRun}
                        alt="DeleteRun"
                        style={{
                          width: "22px",
                          height: "22px",
                          filter: "none", // Use filters if you need color change on hover
                          fill: "#279989 ! important",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.src = HoverDeleteRun;
                          e.currentTarget.style.cursor = "pointer";
                        }}
                        onMouseOut={(e) => (e.currentTarget.src = DeleteRun)}
                        onClick={() => {
                          setSelectedRun(run);
                          setOpenDeleteConfirmation(true);
                        }}
                      />
                      <Typography
                        variant="caption"
                        className="hoverText"
                        sx={{
                          opacity: 0, // Start hidden
                          fontFamily: "Arvo",
                          transition: "opacity 0.3s", // Smooth transition for text visibility
                          position: "absolute", // Position text directly under the icon
                          bottom: "-28px", // Adjust this value as needed
                          whiteSpace: "nowrap", // Prevent text from wrapping
                          color: "#279989 ! important", // Set the text color as needed
                          textAlign: "center",
                        }}
                      >
                        Delete Run
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))
          ) : (
            <Box
              sx={{
                dispay: "flex",
                flexDirection: "row",
                textAlign: "center",
                alignContent: "center",
                minHeight: "200px",
                //border: "2px solid #2799894D",
                marginTop: "20px",
                padding: "8px 16px",
                borderRadius: "8px",
              }}
            >
              <img src={NoRuns} alt="NoRuns" />
              <Typography sx={{ marginTop: "20px" }} variant="h6">
                No Runs Created
              </Typography>
            </Box>
          )}
        </Box>

        {openAddNewRunDialog && (
          <AddNewRunDialog
            openDialog={openAddNewRunDialog}
            selectedRun={selectedRun}
            runsList={runsList}
            handleCloseDialog={() => setOpenAddNewRunDialog(false)}
          />
        )}

        {openDeleteConfirmation && (
          <ConfirmationDialog
            openDialog={openDeleteConfirmation}
            IconUrl={DeleteConfirmationIcon}
            TitleText={`Are you sure you want to delete this ${selectedRun.run_name}?`}
            paraText={`${selectedRun.run_name} will be deleted and all visits assigned to it will be unallocated. Please note that this action cannot be undone.`}
            preFilledValue={selectedRun}
            cancelText="Cancel"
            confirmText="Delete"
            onConfirmFun={handleDeleteRun}
            onCancelFun={() => setOpenDeleteConfirmation(false)}
            handleCloseDialog={() => setOpenDeleteConfirmation(false)}
          />
        )}

        <ToastContainer />
      </Container>
    </ThemeProvider>
  );
}
