import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  IconButton,
  DialogContent,
  Dialog,
  Typography,
  Button,
  Grid,
  DialogActions,
} from "@mui/material";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import TempInactive from "../../../assets/images/clients/tempInactive.svg";
import Dropdown from "../../reusableComponents/Dropdown";
import Calendar from "../../reusableComponents/Calendar";
import Name from "../../reusableComponents/Name";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";

export default function TemporaryInactiveDialog({
  openDialog,
  tempSelectedInactiveData,
  setSelectedInactiveData,
  clientId,
  handleCloseDialog = () => {},
}) {
  const list = [
    { id: 1, text: "Client Hospitalization" },
    { id: 2, text: "Family Support" },
    { id: 3, text: "Client Vacation" },
    { id: 4, text: "Service Review" },
    { id: 5, text: "Change in Condition" },
    { id: 6, text: "Financial Constraints" },
  ];

  const intitalFormState = {
    client_id: 127,
    reason: "",
    inactive_start_date: "",
    inactive_end_date: "",
    notes: "",
  };

  const [form, setForm] = useState(intitalFormState);

  // Update form when tempSelectedInactiveData changes
  useEffect(() => {
    setForm({
      client_id: tempSelectedInactiveData?.clientid || clientId,
      reason: tempSelectedInactiveData?.reason || "",
      inactive_start_date: tempSelectedInactiveData?.inactiveStartDate || "",
      inactive_end_date: tempSelectedInactiveData?.inactiveEndDate || "",
      notes: tempSelectedInactiveData?.notes || "",
    });
  }, [tempSelectedInactiveData]);

  const postInactiveStatus = async (updatedData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/add-clienttempinactivestatus`;
      const token = getCookie();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        console.log("Updated Inactive Status Details");
        handleCloseDialog();
      } else {
        console.log("Failed to update inactive status details.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const putInactiveStatusUpdate = async (updatedData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/update-updateclienttempinactive/${tempSelectedInactiveData.id}/${clientId}`;
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        console.log("Updated Service Status Details");
        handleCloseDialog();
      } else {
        console.log("Failed to update service status details.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const reasonRef = useRef();
  const inactiveStartDateRef = useRef();
  const inactiveEndDateRef = useRef();
  const additionalNotesRef = useRef();

  const handleDropdownChange = (value, name) => {
    setForm((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: value?.text || "", // Fallback to an empty string
      };
      return updatedForm;
    });
  };
  const handleDateChange = (date, name) => {
    setForm({ ...form, [name]: date });
  };

  const handleValueChange = (value, name) => {
    setForm((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: value,
      };
      return updatedForm;
    });
  };

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
    setSelectedInactiveData();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSubmit = () => {
    let isValid = true;
    if (reasonRef.current && !reasonRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      inactiveStartDateRef.current &&
      !inactiveStartDateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      inactiveEndDateRef.current &&
      !inactiveEndDateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      additionalNotesRef.current &&
      !additionalNotesRef.current.handleValidation()
    ) {
      isValid = false;
    }

    if (isValid) {
      if (tempSelectedInactiveData) {
        putInactiveStatusUpdate(form);
      } else {
        postInactiveStatus(form);
        setForm(intitalFormState);
      }
    } else {
      console.log("Form is invalid. Please correct the errors and try again.");
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "50%", // Set your desired width here
          maxWidth: "700px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <img
              style={{
                width: "10%",
                height: "auto",
              }}
              src={TempInactive}
              alt="Temporary Inactive"
            />
            <Typography variant="h3">Temporary Inactive</Typography>
          </Box>

          <Box>
            <Grid container direction="row" alignItems="Top" spacing={2}>
              <Grid item xs={12}>
                <Dropdown
                  ref={reasonRef}
                  name="reason"
                  label="Reason"
                  list={list}
                  onDropdownSelect={(selectedItem) =>
                    handleDropdownChange(selectedItem, "reason")
                  }
                  preFilledvalue={form?.reason}
                  required
                ></Dropdown>
              </Grid>
              <Grid item xs={6}>
                <Calendar
                  ref={inactiveStartDateRef}
                  name="inactive_start_date"
                  label="Inactive Start Date"
                  onDateChange={handleDateChange}
                  preFilledvalue={form.inactive_start_date}
                  required
                ></Calendar>
              </Grid>
              <Grid item xs={6}>
                <Calendar
                  ref={inactiveEndDateRef}
                  name="inactive_end_date"
                  label="Inactive End Date"
                  onDateChange={handleDateChange}
                  preFilledvalue={form.inactive_end_date}
                  minDate={form.inactive_start_date}
                ></Calendar>
              </Grid>
              <Grid item xs={12}>
                <Name
                  ref={additionalNotesRef}
                  name="notes"
                  className="multiline-textfield"
                  label="Additional Notes"
                  list={list}
                  multiline
                  rows={4}
                  onValueChange={handleValueChange}
                  preFilledvalue={form?.notes}
                  required
                ></Name>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button variant="customsave" onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
