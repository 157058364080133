import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import RosterVisitCard from "./RosterVisitCard";
import RosterVisitCardDetails from "./RosterVisitCardDetails";
import UserCard from "./RosterUserCard";
import ClientCard from "./RosterClientCard";
import RosterAssignCarerDialog from "./RosterAssignCarerDialog";
//import RosterUnallocateUsersDialog from "./RosterUnallocateUsersDialog";
import ConfirmationDialog from "../reusableComponents/ConfirmationDialog";
import Admin from "../../assets/icons/Admin.svg";
import ExpandIcon from "@mui/icons-material/ExpandMoreRounded";
import CollapseIcon from "@mui/icons-material/ExpandLessRounded";
import SearchIcon from "@mui/icons-material/Search";

import { getCookie } from "../../utils/utils";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Loader from "../../assets/images/common/logo_animation.gif";

const visitSlots = Array.from({ length: 48 }); // Array for half-hour intervals over 24 hours

export default function RosterTable({ view, selectedDate, selectedRun }) {
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [hoveredUserId, setHoveredUserId] = useState(null);
  const [focusedCardId, setFocusedCardId] = useState(null);
  const [focusedCardRunId, setFocusedCardRunId] = useState(null);
  const [focusedCardRun, setFocusedCardRun] = useState(null);
  const [focusedUserId, setFocusedUserId] = useState(null);
  const [openAssignCarerDialog, setOpenAssignCarerDialog] = useState(false);
  const [unallocateUsersDialog, setUnallocateUsersDialog] = useState(false);
  const [unallocatedExpand, setUnallocatedExpabd] = useState(true);
  const [loading, setLoading] = useState(true); // To track the loading state
  //const [reload, setReLoad] = useState(false); // To track the loading state
  const [selectedVisit, setSelectedVisit] = useState([]);
  const [visits, setVisits] = useState([]);
  const [URowIndex, setURowIndex] = useState(0);
  const [step, setStep] = useState(0);
  const [Users, setUsers] = useState([]);
  const [Clients, setClients] = useState([]);
  const [runs, setRuns] = useState([]);
  const [userRuns, setUserRuns] = useState([]);
  const [noUnRuns, setNoUnRuns] = useState();
  const [noUnVisits, setNoUnVisits] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [filteredClientList, setFilteredClientList] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [showAbove, setShowAbove] = useState(false);
  const cardRefs = useRef(new Map()); // Map to hold refs for each card

  const handlestep = () => {
    setStep(0);
  };
  const handleMouseEnter = (visitId, userId) => {
    const cardRef = cardRefs.current.get(visitId, userId);

    if (cardRef) {
      const cardRect = cardRef.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Check if there's enough space below the card
      setShowAbove(windowHeight - cardRect.bottom < 300);
    }
    if (!focusedCardId) {
      setHoveredCardId(visitId); // Track the specific user id for hover
    }

    if (userId) {
      setHoveredUserId(userId); // Track the specific user id for hover
    }
  };

  const handleMouseLeave = () => {
    setHoveredCardId(null);
    setHoveredUserId(null); // Reset on mouse leave
    setFocusedCardId(null);
    setFocusedCardRunId(null);
  };

  const handlecard = (visitId, userId) => {
    setHoveredCardId(null);
    setHoveredUserId(null); // Reset on mouse leave
    setFocusedCardId(visitId);
    if (userId) {
      setFocusedUserId(userId); // Track the specific user id for hover
    }
  };
  const handlecardRun = (visitRunId, visitRun, userId) => {
    setHoveredCardId(null);
    setHoveredUserId(null); // Reset on mouse leave
    setFocusedCardRunId(visitRunId);
    setFocusedCardRun(visitRun);
    if (userId) {
      setFocusedUserId(userId); // Track the specific user id for hover
    }
  };

  const fetchUsersAPI = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-carer-list`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok === true) {
        // Map carerList to setUsers
        const mappedUsers = data.carerList
          .filter((carer) => carer.status === 1)
          .map((carer) => ({
            userid: carer.userid,
            name: carer.name,
            telephone_codes: carer.telephone_codes,
            contact_number: carer.contact_number,
            role: carer.role,
            allocated: carer.allocated,
            gender: carer.gender,
            hours: carer.hours,
            drive: carer.drive,
            dob: carer.dob,
            dbs: carer.dbs,
            dbs_expiry: carer.dbs_expiry,
            admin: carer.admin,
            status: carer.status,
          }));
        //console.log("mappedUsers", data.carerList);

        // Map clientList to setClients
        const mappedClients = data.clientList.map((client) => ({
          clientid: client.clientid,
          name: client.name,
          visits: client.visits,
        }));

        // Set state for users and clients
        setUsers(mappedUsers);
        setClients(mappedClients);
      } else {
        console.log("Failed to fetch users and client details");
        setUsers([]);
        setClients([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
    setStep(1); // Proceed to the next step
  };

  const fetchVisitsAPI = async () => {
    console.log("fetch started");
    const formattedDate = dayjs(selectedDate).format("YYYY/MM/DD");
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-get-all-visits?visit_date=${formattedDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok === true) {
        // Map visits to visitList
        const mappedVisits = data.clientList
          //.filter((visit) => visit.visitdetailsid != 1634)
          .map((visit, index) => ({
            id: index,
            visitId: visit.visitdetailsid,
            startTime: visit.startTime,
            endTime: visit.endTime,
            userid: visit.userid,
            clientid: visit.clientid,
            status: visit.userid.length > 0 ? visit.status : "Unallocated",
            mainstatus: visit.status,
            userName: visit.userName,
            visit_date: visit.visit_date,
            clientName: visit.clientName,
            maleCarers: visit.maleCarers,
            femaleCarers: visit.femaleCarers,
            actualStartTime: visit.actualStartTime,
            actualEndTime: visit.actualEndTime,
            run: visit.runName,
            runid: visit.runid,
            numberOfCarersRequired: visit.numberOfCarersRequired,
            travelTime: visit.travelTime,
            rowIndex: 0,
            clientRowIndex: 0,
          }));

        const filteredVisits = selectedRun
          ? mappedVisits.filter((visit) => visit.runid === selectedRun.id)
          : mappedVisits;

        setVisits(filteredVisits);
        //console.log("selectedRun", selectedRun);

        //console.log("fetchedvisits", visits);
      } else {
        console.log("Failed to fetch visits details");
        setVisits([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
    setStep(2); // Proceed to the next step
  };

  //console.log(selectedDate);

  const isOverlapping = (visit1, visit2) => {
    // Use actual times if they are available, otherwise fallback to scheduled times
    const visit1Start = visit1.runid
      ? visit1.runStartTime
      : visit1.actualStartTime || visit1.startTime;
    const visit1End = visit1.runid
      ? visit1.runEndTime
      : visit1.actualEndTime || visit1.endTime;
    const visit2Start = visit2.runid
      ? visit2.runStartTime
      : visit2.actualStartTime || visit2.startTime;
    const visit2End = visit2.runid
      ? visit2.runEndTime
      : visit2.actualEndTime || visit2.endTime;

    return visit1Start < visit2End && visit2Start < visit1End;
  };
  const isOverlappingRunvisit = (visit1, visit2) => {
    // Use actual times if they are available, otherwise fallback to scheduled times
    const visit1Start = visit1.actualStartTime || visit1.startTime;
    const visit1End = visit1.actualEndTime || visit1.endTime;
    const visit2Start = visit2.actualStartTime || visit2.startTime;
    const visit2End = visit2.actualEndTime || visit2.endTime;

    return visit1Start < visit2End && visit2Start < visit1End;
  };
  const calculateRunTimes = () => {
    // Step 1: Group visits by `run`

    let runTimes = {};

    // Iterate over each visit to calculate the min start time and max end time per `run`
    visits.forEach((visit) => {
      const { run, startTime, endTime, actualStartTime, actualEndTime } = visit;
      let endtimes = actualEndTime
        ? actualEndTime > endTime
          ? actualEndTime
          : endTime
        : endTime;
      let starttimes = actualStartTime
        ? actualStartTime < startTime
          ? actualStartTime
          : startTime
        : startTime;

      // Only process visits with a `run` (ignoring those without)
      if (run) {
        // Initialize the group in `runTimes` if it doesn't exist

        if (!runTimes[run]) {
          runTimes[run] = {
            minStartTime: starttimes,
            maxEndTime: endtimes,
          };
        } else {
          // Update min start time and max end time for this `run`

          runTimes[run].minStartTime =
            runTimes[run].minStartTime < starttimes
              ? runTimes[run].minStartTime
              : starttimes;

          runTimes[run].maxEndTime =
            runTimes[run].maxEndTime > endtimes
              ? runTimes[run].maxEndTime
              : endtimes;
        }
      }
    });
    //console.log(runTimes);

    // Step 2: Update each visit with the calculated `runStartTime` and `runEndTime`
    const updatedVisitss = visits.map((visit) => {
      const { run } = visit;

      // If the visit is part of a run, use the calculated run times
      if (run && runTimes[run]) {
        const { minStartTime, maxEndTime } = runTimes[run];
        return {
          ...visit,
          runStartTime: minStartTime,
          runEndTime: maxEndTime,
        };
      } else {
        // If not a run, leave `runStartTime` and `runEndTime` undefined or null
        return {
          ...visit,
          runStartTime: undefined, // Or `null`, depending on your preference
          runEndTime: undefined, // Or `null`
        };
      }
    });

    //console.log("updatedRunVisits", updatedVisitss);
    setVisits(updatedVisitss);
    setStep(3); // Proceed to the next step
    return updatedVisitss;
  };
  const runrows = () => {
    const updatedVisits = []; // Array to store visits with a runid
    const processedRunIds = new Set(); // Track processed runids

    // Collect visits with runid
    for (let i = 0; i < visits.length; i++) {
      if (visits[i].runid) {
        updatedVisits.push(visits[i]);
      }
    }

    for (let i = 0; i < updatedVisits.length; i++) {
      const currentVisit = updatedVisits[i];

      // Skip if this runid is already processed
      if (processedRunIds.has(currentVisit.runid)) continue;

      // Find all visits in this run group
      const runGroup = updatedVisits.filter(
        (visit) => visit.runid === currentVisit.runid
      );

      // Check if there's any overlap in this run group
      let hasOverlap = false;
      for (let j = 0; j < runGroup.length; j++) {
        for (let k = j + 1; k < runGroup.length; k++) {
          if (isOverlappingRunvisit(runGroup[j], runGroup[k])) {
            hasOverlap = true;
            break;
          }
        }
        if (hasOverlap) break;
      }

      // If overlap exists, update the first visit of the run group
      if (hasOverlap) {
        const firstVisit = runGroup[0];
        // console.log(
        //   `Updating first visit of runid ${currentVisit.runid}, visit id: ${firstVisit.id}`
        // );

        setVisits((prevVisits) =>
          prevVisits.map((visit) =>
            visit.id === firstVisit.id ? { ...visit, runrow: 2 } : visit
          )
        );
      }

      // Mark the runid as processed
      processedRunIds.add(currentVisit.runid);
    }

    //console.log("Updated visits with runrows:", updatedVisits);
    setStep(4); // Proceed to the next step
  };

  const updateRowIndexForVisit = () => {
    console.log("Step1");

    //console.log(visits);

    setVisits((prevVisits) => {
      const updatedVisits = []; // Array to store visits with updated rowIndex values
      let unindex = 0;

      // Process each visit sequentially
      prevVisits.forEach((currentVisit, index) => {
        let updatedVisit = { ...currentVisit }; // Start with the current visit

        // Only work with visits that have empty userids
        if (currentVisit.userid.length === 0) {
          const overlappingRowIndices = []; // Array to store the row indices of all overlapping visits
          const overlappingVisits = [];
          let maxRowIndex = 0; // Initialize maxRowIndex
          let runrow = 1;
          let firstRunVisit = 0;

          for (let i = 0; i < updatedVisits.length; i++) {
            const visit = updatedVisits[i];

            if (
              visit.userid.length === 0 &&
              isOverlapping(visit, currentVisit)
            ) {
              // Add to overlaps array
              let count = 0;
              let k = 0;
              overlappingRowIndices.push(visit.rowIndex);
              overlappingVisits.push(visit);
              //for (let j = 0; j < overlappingVisits.length; j++) {
              //const vv = overlappingVisits[j];
              if (
                overlappingVisits[overlappingVisits.length - 1].runid &&
                overlappingVisits[overlappingVisits.length - 1].runrow === 2
              ) {
                count = count + 1;
              }
              //}
              if (count === 0) {
                k = Math.max(maxRowIndex, visit.rowIndex + 1);
              } else {
                k = Math.max(maxRowIndex, visit.rowIndex + 2);
                overlappingRowIndices.push(visit.rowIndex + 1);
                //console.log("push", currentVisit.id, visit.rowIndex + 1, count);
              }

              maxRowIndex = k; // Update maxRowIndex
              if (visit.runid === currentVisit.runid) {
                firstRunVisit = 1;
              }
            }
          }
          let mrIndex = [];
          for (let j = 0; j <= overlappingRowIndices.length; j++) {
            if (!overlappingRowIndices.includes(j)) {
              //console.log(j);
              mrIndex.push(j);
            }
            if (mrIndex.length > 0) {
              maxRowIndex = mrIndex[0];
            }
          }
          // console.log(currentVisit.id, mrIndex);
          // console.log("overlappingVisits:", currentVisit.id, overlappingVisits);
          // console.log(
          //   "overlappingRowIndices:",
          //   currentVisit.id,
          //   overlappingRowIndices
          // );
          if (currentVisit.runid) {
            const matchingVisit = overlappingVisits.find(
              (overlapVisit) => overlapVisit.runid === currentVisit.runid
            );
            if (matchingVisit) {
              let count = 0;
              let overlappedindex = 0;
              for (let i = 0; i < updatedVisits.length; i++) {
                if (
                  updatedVisits[i].runid === currentVisit.runid &&
                  isOverlappingRunvisit(currentVisit, updatedVisits[i])
                ) {
                  count = count + 1;
                  overlappedindex = updatedVisits[i].rowIndex;
                  //console.log("count", count);
                }
              }
              if (count > 0) {
                if (matchingVisit.rowIndex + 1 === overlappedindex) {
                  updatedVisit.rowIndex = matchingVisit.rowIndex; // Use the matched row index
                } else {
                  updatedVisit.rowIndex = matchingVisit.rowIndex + 1; // Use the matched row index
                }
              } else {
                updatedVisit.rowIndex = matchingVisit.rowIndex; // Use the matched row index
              }

              //console.log(currentVisit.id);
              //return; // Skip further processing
              //break;
            } else {
              updatedVisit.rowIndex = maxRowIndex;
            }
          } else {
            updatedVisit.rowIndex = maxRowIndex;
          }
          if (updatedVisit.rowIndex > unindex) {
            //console.log("RowIndex Unallocated", updatedVisit.rowIndex, unindex);
            unindex = updatedVisit.rowIndex;
          }
        } else {
          // Initialize an object to store unique row indices for each user in the current visit
          const userRowIndexMap = {};

          // Loop through each userid in the current visit
          currentVisit.userid.forEach((userId) => {
            let userZ = 0; // Initialize a counter for this specific user
            const overlappingRowIndices = []; // Array to store the row indices of all overlapping visits
            const overlappingVisits = [];
            let maxRowIndex = 0; // Initialize maxRowIndex
            let firstRunVisit = 0;

            // Calculate max row index for this specific user by checking overlaps in previous visits

            for (let i = 0; i < updatedVisits.length; i++) {
              const visit = updatedVisits[i];

              if (
                visit.userid.includes(userId) &&
                isOverlapping(visit, currentVisit)
              ) {
                // Add to overlaps array
                let count = 0;
                let k = 0;
                overlappingRowIndices.push(visit[`rowindex${userId}`]);
                overlappingVisits.push(visit);
                //for (let j = 0; j < overlappingVisits.length; j++) {
                //const vv = overlappingVisits[j];
                if (
                  overlappingVisits[overlappingVisits.length - 1].runid &&
                  overlappingVisits[overlappingVisits.length - 1].runrow === 2
                ) {
                  count = count + 1;
                }
                //}
                if (count === 0) {
                  k = Math.max(maxRowIndex, visit[`rowindex${userId}`] + 1);
                } else {
                  k = Math.max(maxRowIndex, visit[`rowindex${userId}`] + 2);
                  overlappingRowIndices.push(visit[`rowindex${userId}`] + 1);
                  // console.log(
                  //   "push",
                  //   currentVisit.id,
                  //   visit[`rowindex${userId}`] + 1,
                  //   count
                  // );
                }

                maxRowIndex = k; // Update maxRowIndex
                if (visit.runid === currentVisit.runid) {
                  firstRunVisit = 1;
                }
              }
            }
            let mrIndex = [];
            for (let j = 0; j <= overlappingRowIndices.length; j++) {
              if (!overlappingRowIndices.includes(j)) {
                //console.log(j);
                mrIndex.push(j);
              }
              if (mrIndex.length > 0) {
                maxRowIndex = mrIndex[0];
              }
            }
            // console.log(currentVisit.id, mrIndex);
            // console.log(
            //   "overlappingVisits:",
            //   currentVisit.id,
            //   overlappingVisits
            // );
            // console.log(
            //   "overlappingRowIndices:",
            //   currentVisit.id,
            //   overlappingRowIndices
            // );

            if (currentVisit.runid) {
              const matchingVisit = overlappingVisits.find(
                (overlapVisit) => overlapVisit.runid === currentVisit.runid
              );
              if (matchingVisit) {
                let count = 0;
                let overlappedindex = 0;
                for (let i = 0; i < updatedVisits.length; i++) {
                  if (
                    updatedVisits[i].runid === currentVisit.runid &&
                    isOverlappingRunvisit(currentVisit, updatedVisits[i])
                  ) {
                    count = count + 1;
                    overlappedindex = updatedVisits[i][`rowindex${userId}`];

                    //console.log("count", count);
                  }
                }
                if (count > 0) {
                  if (
                    matchingVisit[`rowindex${userId}`] + 1 ===
                    overlappedindex
                  ) {
                    userZ = matchingVisit[`rowindex${userId}`]; // Use the matched row index
                  } else {
                    userZ = matchingVisit[`rowindex${userId}`] + 1; // Use the matched row index
                  }
                } else {
                  userZ = matchingVisit[`rowindex${userId}`]; // Use the matched row index
                }

                //console.log(currentVisit.id);
                //return; // Skip further processing
                //break;
              } else {
                userZ = maxRowIndex;
              }
            } else {
              userZ = maxRowIndex;
            }
            //userZ = maxRowIndex;

            // Store the row index for this user in the map
            userRowIndexMap[`rowindex${userId}`] = userZ;
          });

          // Update visit with dynamically created rowindex properties for each userid
          updatedVisit = { ...updatedVisit, ...userRowIndexMap };
        }

        // Add the updated visit to the array so the next visit can access it
        updatedVisits.push(updatedVisit);
        //console.log("updatedVisits", updatedVisits);
      });
      setURowIndex(unindex);

      return updatedVisits; // Update state with all visits processed sequentially
    });

    setStep(5); // Proceed to the next step
  };
  const updateClientRowIndexForVisit = () => {
    console.log("Step2");

    //console.log(visits);

    setVisits((prevVisits) => {
      const updatedVisits = []; // Array to store visits with updated clientRowIndex values
      let unindex = 0;

      // Process each visit sequentially
      prevVisits.forEach((currentVisit, index) => {
        let updatedVisit = { ...currentVisit }; // Start with the current visit

        // Only work with visits that have empty userids
        //if (currentVisit.userid.length === 0) {
        const overlappingRowIndices = []; // Array to store the row indices of all overlapping visits
        const overlappingVisits = [];
        let maxRowIndex = 0; // Initialize maxRowIndex

        for (let i = 0; i < updatedVisits.length; i++) {
          const visit = updatedVisits[i];

          if (
            visit.clientid === currentVisit.clientid &&
            isOverlapping(visit, currentVisit)
          ) {
            // Add to overlaps array
            let count = 0;
            let k = 0;
            overlappingRowIndices.push(visit.clientRowIndex);
            overlappingVisits.push(visit);

            k = Math.max(maxRowIndex, visit.clientRowIndex + 1);

            maxRowIndex = k; // Update maxRowIndex
          }
        }
        let mrIndex = [];
        for (let j = 0; j <= overlappingRowIndices.length; j++) {
          if (!overlappingRowIndices.includes(j)) {
            //console.log(j);
            mrIndex.push(j);
          }
          if (mrIndex.length > 0) {
            maxRowIndex = mrIndex[0];
          }
        }
        //console.log("rows", currentVisit.id, overlappingRowIndices);
        //console.log("maxRowIndex", currentVisit.id, maxRowIndex);
        updatedVisit.clientRowIndex = maxRowIndex;
        //}

        // Add the updated visit to the array so the next visit can access it
        updatedVisits.push(updatedVisit);
        //console.log("updatedVisits", updatedVisits);
      });
      //console.log(updatedVisits);
      return updatedVisits; // Update state with all visits processed sequentially
    });

    setStep(6); // Proceed to the next step
  };

  const updateUserRowIndices = () => {
    console.log("Step3");
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        // Filter visits that include the current user
        const matchingVisits = visits.filter((visit) =>
          visit.userid.includes(user.userid)
        );

        if (matchingVisits.length > 0) {
          // Calculate the maximum row index for this user across all matching visits
          const maxUserRowIndex = Math.max(
            ...matchingVisits.map(
              (visit) => visit[`rowindex${user.userid}`] || 0
            )
          );

          // console.log(
          //   "Matching User ID:",
          //   user.userid,
          //   "Max User Row Index:",
          //   maxUserRowIndex
          // );

          // Set the maximum row index for this user
          return { ...user, rowIndex: maxUserRowIndex };
        }

        return user; // If no matching visits, return user unchanged
      })
    );
    // Proceed to the next step
    setStep(7);
  };

  const updateClientRowIndices = () => {
    console.log("Step 4: Updating Client Row Indices");

    setClients((prevClients) =>
      prevClients.map((client) => {
        // Find all visits that match the current client's clientid
        const matchingVisits = visits.filter(
          (visit) => visit.clientid === client.clientid
        );

        //console.log("clientmatchingVisits", matchingVisits);

        // If matching visits are found, calculate the maximum clientRowIndex
        if (matchingVisits.length > 0) {
          const maxClientRowIndex = matchingVisits.reduce(
            (maxIndex, visit) => Math.max(maxIndex, visit.clientRowIndex ?? 0), // Use clientRowIndex or 0 if undefined
            0
          );

          // Return the updated client with the max clientRowIndex
          return { ...client, rowIndex: maxClientRowIndex };
        }

        // If no matching visits are found, return the client unchanged
        return client;
      })
    );

    setStep(8); // Proceed to the next step
  };
  const updateVisitsWithRunRows = () => {
    // setVisits((prevVisits) => {
    //   const runRowMap = {}; // To store unique row indices for each runid

    //   // First pass: build runRowMap
    //   prevVisits.forEach((visit) => {
    //     if (visit.runid) {
    //       if (!runRowMap[visit.runid]) {
    //         runRowMap[visit.runid] = new Set(); // Initialize Set for this runid
    //       }
    //       // Add the rowIndex to the Set for the runid
    //       runRowMap[visit.runid].add(visit.rowIndex);
    //     }
    //   });

    //   // Second pass: update visits with runRow
    //   const updatedVisits = prevVisits.map((visit) => {
    //     if (visit.runid && runRowMap[visit.runid]) {
    //       // Calculate runRow as the size of the unique rowIndex set
    //       return { ...visit, runrow: runRowMap[visit.runid].size };
    //     }
    //     return { ...visit, runrow: 0 }; // Default runRow to 0 if no runid
    //   });

    //   return updatedVisits; // Update visits in state
    // });
    setStep(9); // Proceed to the next step
  };

  const updateRuns = () => {
    console.log("Step 5: Updating Runs");
    //console.log(visits);

    let runs = [];

    visits.forEach((visit) => {
      if (
        visit.runid &&
        visit.userid.length === 0 &&
        !runs.some((run) => run.runid === visit.runid)
      ) {
        runs.push(visit);
      }
    });
    setRuns(runs);
    //console.log(visits);
    setStep(10); // Proceed to the next step
  };

  const updateUserRuns = () => {
    console.log("Step 5: Updating Runs");

    const runs = [];
    const runUserMap = {}; // Map of runid -> Set of unique userids

    // Step 1: Identify all userids for each runid
    visits.forEach((visit) => {
      if (visit.runid) {
        if (!runUserMap[visit.runid]) {
          runUserMap[visit.runid] = new Set();
        }
        visit.userid.forEach((userid) => runUserMap[visit.runid].add(userid));
      }
    });
    console.log("runUserMap", runUserMap);

    // Step 2: Process each run separately to find the first visit for each userid
    Object.entries(runUserMap).forEach(([runid, userid]) => {
      const seenUserIdsInRun = new Set(); // Track userids added for this specific run
      console.log("Point1");
      visits.forEach((visit) => {
        console.log("Point2");
        console.log("Point2runid", runid);
        console.log("Point2visitrunid", visit.runid);

        if (visit.runid === Number(runid)) {
          console.log("Point3");
          visit.userid.forEach((userid) => {
            if (!seenUserIdsInRun.has(userid)) {
              // Dynamically collect row indices for this user in this run
              const rowIndices = [];
              visits.forEach((v) => {
                if (v.runid === Number(runid) && v.userid.includes(userid)) {
                  const dynamicKey = `rowindex${userid}`;
                  console.log("dynamicKey", dynamicKey);
                  if (
                    v.hasOwnProperty(dynamicKey) &&
                    v[dynamicKey] !== null &&
                    !rowIndices.includes(v[dynamicKey])
                  ) {
                    rowIndices.push(v[dynamicKey]);
                  }
                }
              });

              // Add the visit with the row indices
              runs.push({
                ...visit,
                runuserid: userid, // Add userid that caused this visit to be added
                rowIndices, // Include all row indices for this user in the run
              });
              seenUserIdsInRun.add(userid); // Mark this userid as processed for this run
            }
          });
        }
      });
    });

    console.log("Runs with users and row indices:", runs);

    setUserRuns(runs);
    setStep(11); // Proceed to the next step
  };

  useEffect(() => {
    //setVisits([]);
    fetchUsersAPI();
  }, [selectedDate, selectedRun]);

  useEffect(() => {
    setSearchValue(""); // Clear the text field
    setFilteredUserList(Users); // Optionally reset the filtered user list
    setFilteredClientList(Clients);
  }, [view]);

  useEffect(() => {
    // Run the first step initially
    setLoading(true);
    if (step === 0) fetchUsersAPI();
    if (step === 1) fetchVisitsAPI();
    if (step === 2) calculateRunTimes();
    if (step === 3) runrows();
    if (step === 4) updateRowIndexForVisit();
    if (step === 5) updateClientRowIndexForVisit();
    if (step === 6) updateUserRowIndices();
    if (step === 7) updateClientRowIndices();
    if (step === 8) updateVisitsWithRunRows();
    if (step === 9) updateRuns();
    if (step === 10) updateUserRuns();
    if (step === 11) {
      setLoading(false); // Update loading state
      setFilteredUserList(Users);
      setFilteredClientList(Clients);
      let noUnallocatedVisits = 0;
      //let noUnallocatedRuns=0;
      let runIds = new Set();
      visits.forEach((visit) => {
        if (visit.userid.length === 0 && visit.runid === "") {
          noUnallocatedVisits++; // Increment count for visits with userid as [] and runid as ""
        }
        if (visit.userid.length === 0 && visit.runid) {
          runIds.add(visit.runid); // Add unique runids to the set
        }
      });

      let noUnallocatedRuns = runIds.size; // Count of unique runids
      setNoUnVisits(noUnallocatedVisits);
      setNoUnRuns(noUnallocatedRuns);
    }

    //if (step === 6) console.log("runs", runs);
  }, [step]);

  const getGridIndex = (visit) => {
    const [hour, minute] = visit.split(":").map(Number);
    return hour * 2 + (minute >= 30 ? 1 : 0);
  };

  const getWidth = (visit) => {
    const [hour, minute] = visit.split(":").map(Number);
    return ((minute % 30) / 30) * 100;
  };

  const handleCloseAssignCarerDialog = () => {
    setOpenAssignCarerDialog(false);
  };
  const handleUnallocateExpand = () => {
    setUnallocatedExpabd(true);
  };
  const handleUnallocateCollapse = () => {
    setUnallocatedExpabd(false);
  };
  const handleCloseUnallocateUsersDialog = () => {
    setUnallocateUsersDialog(false);
  };
  const PostUnallocateUsers = async () => {
    try {
      const body = {
        ids: [{ [selectedVisit.visitId]: [] }],
      };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-run-assign-users`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        //setSelectedUser([]);
      } else {
        console.log("Failed to set assigned carers");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value); // Update the input's state

    // Filter users based on the input value

    if (view === "Carer View") {
      let filtData = Users.filter((each) => {
        return each.name.toLowerCase().includes(value);
      });
      setFilteredUserList(filtData);
    } else if (view === "Client View") {
      let filtData = Clients.filter((each) => {
        return each.name.toLowerCase().includes(value);
      });
      setFilteredClientList(filtData);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 50px)",
          backgroundColor: "white",
          border: "1px solid #dddddd",
          overflowY: "auto",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Loader} alt="Loading..." />
      </Box>
    ); // Render nothing while loading
    // Alternatively, you can render a loader like: <p>Loading...</p>
  }
  return (
    <Box
      //onClick={handleMouseLeave}
      sx={{
        width: "100%",
        height: "calc(100% - 50px)",
        backgroundColor: "white",
        border: "1px solid #dddddd",
        overflowY: "auto",
      }}
    >
      {/* Header Row */}
      <Box
        sx={{
          display: "flex",
          height: "35px",
          position: "sticky",
          top: 0,
          backgroundColor: "#ffffff",
          zIndex: 3,
        }}
      >
        <Box
          sx={{
            width: "190px",
            borderBottom: "1px solid #dddddd",
            alignContent: "center",
          }}
        >
          <Box sx={{ padding: "4px 15px 4px 10px" }}>
            <TextField
              value={searchValue} // Controlled value
              onChange={handleSearchChange}
              size="small"
              placeholder={
                view === "Carer View"
                  ? "Search user"
                  : view === "Client View"
                  ? "Search Client"
                  : "Search"
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ margin: 0, padding: 0 }}
                  >
                    <SearchIcon
                      sx={{
                        color: "#279989",
                        width: "20px",
                        marginLeft: "2px",
                        marginTop: "2px",
                        marginRight: "2px",
                        padding: 0,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                marginTop: 0,
                "& .MuiInputBase-root": {
                  height: "24px",
                  margin: 0,
                  padding: 0,
                },
                "& .MuiInputBase-input": {
                  margin: 0,
                  padding: 0,
                },
              }}
            ></TextField>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            direction="row"
            sx={{
              height: "100%",

              borderBottom: "1px solid #dddddd",
              borderRight: "1px solid #dddddd",
              textAlign: "center",
              position: "relative",
            }}
          >
            {Array.from({ length: 24 }).map((_, index) => (
              <Grid
                item
                xs={0.5}
                key={index}
                sx={{
                  position: "relative",
                  "&::after": {
                    content: `"${index < 10 ? `0${index}` : index}"`, // Displays each number
                    position: "absolute",
                    top: "8px", // Adjusts the vertical position of the numbers
                    left: "0",
                    transform: "translateX(-50%)",
                    fontSize: "12px",
                    variant: "body3",
                  },
                }}
              ></Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Unallocated Visits Row */}
      {view === "Carer View" && (
        <Box
          sx={{
            display: "flex",
            minHeight: unallocatedExpand === true ? "123px" : "105px",
            height:
              unallocatedExpand === true
                ? `${25 + (URowIndex + 1) * 79}px`
                : "105px", // Use template literal for dynamic height
          }}
        >
          <Box
            sx={{
              //display: "flex",
              //direction: "row",
              width: "190px ! important",
              borderBottom: "1px solid #dddddd",
              alignContent: "center",
              paddingLeft: "8px",
              position: "relative",
            }}
          >
            <Box sx={{ position: "absolute", right: 0, top: 5 }}>
              {unallocatedExpand === true ? (
                <IconButton
                  color="inherit"
                  onClick={handleUnallocateCollapse}
                  aria-label="Close"
                >
                  <CollapseIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  onClick={handleUnallocateExpand}
                  aria-label="Close"
                >
                  <ExpandIcon />
                </IconButton>
              )}
            </Box>

            <Typography variant="h6">Unallocated</Typography>
            {/* {unallocatedExpand === false && ( */}
            <Box>
              <Typography variant="body4" sx={{ textAlign: "center" }}>
                Visits: {noUnVisits} /{" "}
              </Typography>
              <Typography variant="body4" sx={{ textAlign: "center" }}>
                Runs: {noUnRuns}
              </Typography>
            </Box>
            {/* )} */}
          </Box>
          <Box sx={{ width: "100%", position: "relative" }}>
            <Grid
              container
              direction="row"
              sx={{
                height: "100%",
                backgroundColor: "#ffffff",
                borderBottom: "1px solid #dddddd",
                borderRight: "1px solid #dddddd",
              }}
            >
              {visitSlots.map((_, gridIndex) => {
                let runrowspace = 20;
                return (
                  <Grid
                    item
                    xs={0.25}
                    key={gridIndex}
                    sx={{
                      borderLeft: "1px solid #dddddd",
                      position: "relative",
                      //marginTop: "20px",
                    }}
                  >
                    {unallocatedExpand === true &&
                      runs
                        .filter(
                          (visit) => visit.userid.length === 0 && visit.runid
                        )
                        .map((visit) => {
                          const startIndex = getGridIndex(visit.runStartTime);
                          const endIndex = getGridIndex(visit.runEndTime);
                          const gridSpan = endIndex - startIndex;
                          const startWidth = getWidth(visit.runStartTime);
                          const endWidth = getWidth(visit.runEndTime);
                          const runrow = visit.runrow ? visit.runrow : 1;
                          const runpop = runrow - 1;

                          //runrowspace = runrowspace + visit.runid ? 20 : 0;

                          //addToTimeCheck(visit.startTime, visit.endTime);

                          //setTimeChecks(visit.id);

                          if (gridIndex === startIndex) {
                            return (
                              <Box
                                //onMouseEnter={() => handleMouseEnter(visit.id)}
                                onMouseLeave={handleMouseLeave}
                                //ref={(el) => cardRefs.current.set(visit.id, el)} // Set ref in the map
                                key={visit.id}
                                //onClick={() => handlecard(visit.id)}
                              >
                                <Box
                                  key={visit.runid}
                                  sx={{
                                    position: "absolute",
                                    top: 2 + visit.rowIndex * 79 + runrowspace,
                                    left: `calc(${startWidth}% - ${3}px)`,
                                    height: `calc(${
                                      runrow * 60 + (runrow - 1) * 20
                                    }px)`,
                                    width: `calc(${
                                      gridSpan * 100 - startWidth + endWidth
                                    }% + ${gridSpan + 5}px)`,
                                    backgroundColor: "none",
                                    color: "white",
                                    zIndex: 2,
                                    borderRadius: "8px",
                                    //overflow: "hidden",
                                    border: "1px solid #279989",
                                    //position: "relative",
                                  }}
                                >
                                  <Box
                                    key={visit.runid}
                                    ref={(el) =>
                                      cardRefs.current.set(visit.runid, el)
                                    } // Set ref in the map
                                    onClick={() =>
                                      handlecardRun(visit.runid, visit.run)
                                    }
                                    sx={{
                                      backgroundColor: "#279989",
                                      padding: "2px 8px",
                                      position: "absolute",
                                      bottom: -20,
                                      height: 20,
                                      overflow: "hidden",
                                      borderRadius: "0px 0px 8px 9px",
                                      left: 5,
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Typography
                                      //variant="body4"
                                      sx={{
                                        color: "#ffffff",
                                        fontSize: "10px",
                                        padding: 0,
                                        margin: 0,
                                        fontFamily: "Lora",
                                        fontWeight: "BOLD",
                                        //zIndex: 25,
                                      }}
                                    >
                                      {visit.run}
                                    </Typography>
                                  </Box>
                                </Box>
                                {focusedCardRunId === visit.runid && (
                                  <Box
                                    key={visit.runid}
                                    sx={{
                                      position: "absolute",
                                      top:
                                        70 +
                                        visit.rowIndex * 79 +
                                        runrowspace +
                                        runpop * 79,

                                      left: `${startWidth}%`,
                                      mt: showAbove ? "-9px" : "9px",
                                      width: "150px",
                                      border: "1px solid #279989",
                                      //borderTop: "0px",
                                      borderRadius: "8px",
                                      backgroundColor: "#ffffff",
                                      zIndex: 25,
                                      textAlign: "left",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        padding: " 5px 10px",
                                        borderBottom: "1px solid #279989",
                                        cursor: "pointer",
                                        "&:hover": {
                                          backgroundColor: "#BFE1DC", // Hover color
                                          borderRadius: "8px 8px 0 0",
                                        },
                                      }}
                                      onClick={() => {
                                        setOpenAssignCarerDialog(true);
                                        setSelectedVisit(visit);
                                      }}
                                    >
                                      <Typography variant="body3">
                                        Assign Users
                                      </Typography>
                                    </Box>
                                    <Link
                                      to={`/roster/create-run?date=${encodeURIComponent(
                                        selectedDate
                                      )}&runname=${encodeURIComponent(
                                        focusedCardRun
                                      )}&runid=${encodeURIComponent(
                                        focusedCardRunId
                                      )}`}
                                      style={{
                                        textDecoration: "none",
                                        //right: "20px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          padding: "5px 10px",
                                          cursor: "pointer",
                                          "&:hover": {
                                            backgroundColor: "#BFE1DC", // Hover color
                                            borderRadius: "0 0 8px 8px",
                                          },
                                        }}
                                      >
                                        <Typography variant="body3">
                                          Manage Run
                                        </Typography>
                                      </Box>
                                    </Link>
                                  </Box>
                                )}
                              </Box>
                            );
                          }
                          return null;
                        })}
                    {unallocatedExpand === true &&
                      visits
                        .filter((visit) => visit.userid.length === 0)
                        .map((visit) => {
                          const startIndex = getGridIndex(visit.startTime);
                          const endIndex = getGridIndex(visit.endTime);
                          const gridSpan = endIndex - startIndex;
                          const startWidth = getWidth(visit.startTime);
                          const endWidth = getWidth(visit.endTime);

                          //addToTimeCheck(visit.startTime, visit.endTime);

                          //setTimeChecks(visit.id);

                          if (gridIndex === startIndex) {
                            return (
                              <Box
                                onMouseEnter={() => handleMouseEnter(visit.id)}
                                onMouseLeave={handleMouseLeave}
                                ref={(el) => cardRefs.current.set(visit.id, el)} // Set ref in the map
                                key={visit.id}
                                onClick={() =>
                                  visit.runid ? "" : handlecard(visit.id)
                                }
                              >
                                <Box
                                  key={visit.id}
                                  sx={{
                                    position: "absolute",
                                    top: 5 + visit.rowIndex * 79 + runrowspace,
                                    left: `${startWidth}%`,
                                    height: "54px",
                                    width: `calc(${
                                      gridSpan * 100 - startWidth + endWidth
                                    }% + ${gridSpan - 1}px)`,
                                    backgroundColor: "#279989",
                                    color: "white",
                                    zIndex: 2,
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    border: "1px solid #279989",
                                  }}
                                >
                                  <RosterVisitCard visit={visit} view={view} />
                                </Box>
                                {focusedCardId === visit.id &&
                                  visit.runid === "" && (
                                    <Box
                                      key={visit.id}
                                      sx={{
                                        position: "absolute",
                                        top:
                                          (showAbove ? -248 : 50) +
                                          visit.rowIndex * 79 +
                                          runrowspace, // Show above or below based on space
                                        left: `${startWidth}%`,
                                        mt: showAbove ? "-9px" : "9px",
                                        width: "150px",
                                        border: "1px solid #279989",
                                        //borderTop: "0px",
                                        borderRadius: "8px",
                                        backgroundColor: "#ffffff",
                                        zIndex: 5,
                                        textAlign: "left",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          padding: " 5px 10px",
                                          cursor: "pointer",
                                          borderBottom: "1px solid #279989",
                                          "&:hover": {
                                            backgroundColor: "#BFE1DC", // Hover color
                                            borderRadius: "8px 8px 0 0",
                                          },
                                        }}
                                        onClick={() => {
                                          setOpenAssignCarerDialog(true);
                                          setSelectedVisit(visit);
                                        }}
                                      >
                                        <Typography variant="body3">
                                          Assign Users
                                        </Typography>
                                      </Box>
                                      <Link
                                        to={{
                                          pathname: "/roster/create-run",
                                          state: { selectedDate }, // Pass the selected date here
                                        }}
                                        style={{
                                          textDecoration: "none",
                                          //right: "20px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            padding: "5px 10px",
                                            cursor: "pointer",
                                            "&:hover": {
                                              backgroundColor: "#BFE1DC", // Hover color
                                              borderRadius: "0 0 8px 8px",
                                            },
                                          }}
                                        >
                                          <Typography variant="body3">
                                            Manage Run
                                          </Typography>
                                        </Box>
                                      </Link>
                                    </Box>
                                  )}
                                {hoveredCardId === visit.id && (
                                  <Box
                                    key={visit.id}
                                    sx={{
                                      position: "absolute",
                                      top:
                                        (showAbove ? -252 : 54) +
                                        visit.rowIndex * 79 +
                                        runrowspace, // Show above or below based on space
                                      left: `${startWidth}%`,
                                      mt: showAbove ? "-9px" : "9px",
                                      width: "250px",
                                      zIndex: 5,
                                    }}
                                  >
                                    <RosterVisitCardDetails visit={visit} />
                                  </Box>
                                )}
                              </Box>
                            );
                          }
                          return null;
                        })}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      )}

      {/* User Rows with Visits */}
      {view === "Carer View" && (
        <>
          {filteredUserList.map((user) => (
            <Box
              key={user.userid}
              sx={{
                display: "flex",
                minHeight: "65px",
                height: `${25 + (user.rowIndex + 1) * 79}px`,
              }}
            >
              <Box
                sx={{
                  width: "190px ! important",
                  borderBottom: "1px solid #dddddd",
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "8px",
                }}
              >
                <UserCard userDetails={user} />
              </Box>
              <Box sx={{ width: "100%", position: "relative" }}>
                <Grid
                  container
                  direction="row"
                  sx={{
                    height: "100%",
                    backgroundColor: "#E3F2F0",
                    borderBottom: "1px solid #dddddd",
                    borderRight: "1px solid #dddddd",
                  }}
                >
                  {visitSlots.map((_, gridIndex) => {
                    let runrowspace = 20;
                    return (
                      <Grid
                        item
                        xs={0.25}
                        key={gridIndex}
                        sx={{
                          borderLeft: "1px solid #dddddd",
                          position: "relative",
                        }}
                      >
                        {userRuns
                          .filter((visit) => visit.runuserid === user.userid)
                          .map((visit) => {
                            const startIndex = getGridIndex(visit.runStartTime);
                            const endIndex = getGridIndex(visit.runEndTime);
                            const gridSpan = endIndex - startIndex;
                            const startWidth = getWidth(visit.runStartTime);
                            const endWidth = getWidth(visit.runEndTime);
                            const runrow = visit.rowIndices.length;
                            const runpop = runrow - 1;
                            //console.log(visit.id, visit.runid, visit.runrow);
                            //runrowspace = runrowspace + visit.runid ? 20 : 0;

                            //addToTimeCheck(visit.startTime, visit.endTime);

                            //setTimeChecks(visit.id);

                            if (gridIndex === startIndex) {
                              return (
                                <Box
                                  // onMouseEnter={() =>
                                  //   handleMouseEnter(visit.id)
                                  // }
                                  onMouseLeave={handleMouseLeave}
                                  // ref={(el) =>
                                  //   cardRefs.current.set(visit.id, el)
                                  // } // Set ref in the map
                                  key={visit.id}
                                  //onClick={() => handlecard(visit.id)}
                                >
                                  <Box
                                    key={visit.id}
                                    sx={{
                                      position: "absolute",

                                      top:
                                        2 +
                                        (visit[`rowindex${user.userid}`] || 0) *
                                          79 +
                                        runrowspace,
                                      left: `calc(${startWidth}% - ${3}px)`,
                                      height: `calc(${
                                        runrow * 60 + (runrow - 1) * 20
                                      }px)`,
                                      width: `calc(${
                                        gridSpan * 100 - startWidth + endWidth
                                      }% + ${gridSpan + 5}px)`,
                                      backgroundColor: "none",
                                      color: "white",
                                      zIndex: 2,
                                      borderRadius: "8px",
                                      //overflow: "hidden",
                                      border: "1px solid #279989",
                                      //position: "relative",
                                    }}
                                  >
                                    <Box
                                      key={visit.runid}
                                      //onMouseLeave={handleMouseLeave}
                                      onClick={() =>
                                        handlecardRun(
                                          visit.runid,
                                          visit.run,
                                          user.userid
                                        )
                                      }
                                      ref={(el) =>
                                        cardRefs.current.set(visit.runid, el)
                                      } // Use combined key for unique ref
                                      sx={{
                                        backgroundColor: "#279989",
                                        padding: "2px 8px",
                                        position: "absolute",
                                        bottom: -20,
                                        height: 20,
                                        overflow: "hidden",

                                        borderRadius: "0 0 8px 8px",
                                        left: 5,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Typography
                                        //variant="body4"
                                        sx={{
                                          color: "#ffffff",
                                          fontSize: "10px",
                                          padding: 0,
                                          margin: 0,
                                          fontFamily: "Lora",
                                          fontWeight: "BOLD",
                                        }}
                                      >
                                        {visit.run}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  {focusedCardRunId === visit.runid &&
                                    focusedUserId === user.userid && (
                                      <Box
                                        key={visit.id}
                                        sx={{
                                          position: "absolute",
                                          top:
                                            79 *
                                              (visit[`rowindex${user.userid}`] +
                                                runpop +
                                                1) +
                                            runrowspace,
                                          left: `${startWidth}%`,
                                          //mt: showAbove ? "-79px" : "9px",
                                          width: "150px",
                                          border: "1px solid #279989",
                                          //borderTop: "0px",
                                          borderRadius: "8px",
                                          backgroundColor: "#ffffff",
                                          zIndex: 5,
                                          textAlign: "left",
                                          //cursor: "pointer",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            padding: " 5px 10px",
                                            borderBottom: "1px solid #279989",
                                            cursor: "pointer",
                                            "&:hover": {
                                              backgroundColor: "#BFE1DC", // Hover color
                                              borderRadius: "8px 8px 0 0",
                                            },
                                          }}
                                          onClick={() => {
                                            setOpenAssignCarerDialog(true);
                                            setSelectedVisit(visit);
                                          }}
                                        >
                                          <Typography variant="body3">
                                            Change Users
                                          </Typography>
                                        </Box>

                                        <Link
                                          to={`/roster/create-run?date=${encodeURIComponent(
                                            selectedDate
                                          )}&runname=${encodeURIComponent(
                                            focusedCardRun
                                          )}&runid=${encodeURIComponent(
                                            focusedCardRunId
                                          )}`}
                                          style={{
                                            textDecoration: "none",
                                            //right: "20px",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              padding: "5px 10px",
                                              cursor: "pointer",
                                              "&:hover": {
                                                backgroundColor: "#BFE1DC", // Hover color
                                                borderRadius: "0 0 8px 8px",
                                              },
                                            }}
                                          >
                                            <Typography variant="body3">
                                              Manage Run
                                            </Typography>
                                          </Box>
                                        </Link>
                                      </Box>
                                    )}
                                </Box>
                              );
                            }
                            return null;
                          })}
                        {visits
                          .filter((event) => event.userid.includes(user.userid))
                          .map((visit) => {
                            const startIndex = getGridIndex(visit.startTime);
                            const endIndex = getGridIndex(visit.endTime);
                            const gridSpan = endIndex - startIndex;
                            const startWidth = getWidth(visit.startTime);
                            const endWidth = getWidth(visit.endTime);

                            const startIndexActual = getGridIndex(
                              visit.actualStartTime
                            );
                            const endIndexActual = getGridIndex(
                              visit.actualEndTime
                            );
                            const gridSpanActual =
                              endIndexActual - startIndexActual;
                            const startWidthActual = getWidth(
                              visit.actualStartTime
                            );
                            //console.log("startWidthActual", startWidthActual);
                            //console.log("startWidth", startWidth);
                            const endWidthActual = getWidth(
                              visit.actualEndTime
                            );

                            if (
                              gridIndex ===
                              (startIndexActual ? startIndexActual : startIndex)
                            ) {
                              return (
                                <Box
                                  onMouseEnter={() =>
                                    handleMouseEnter(visit.id, user.userid)
                                  } // Pass user id
                                  onMouseLeave={handleMouseLeave}
                                  onClick={() =>
                                    visit.runid
                                      ? ""
                                      : handlecard(visit.id, user.userid)
                                  }
                                  ref={(el) =>
                                    cardRefs.current.set(visit.id, el)
                                  } // Use combined key for unique ref
                                  key={visit.id} // Ensure unique key for each combination
                                >
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top:
                                        5 +
                                        (visit[`rowindex${user.userid}`] || 0) *
                                          79 +
                                        runrowspace,
                                      left: `${
                                        startWidthActual
                                          ? startWidthActual
                                          : startWidth
                                      }%`,
                                      height: "54px",
                                      width: `calc(${
                                        (gridSpanActual
                                          ? gridSpanActual
                                          : gridSpan) *
                                          100 -
                                        (startWidthActual
                                          ? startWidthActual
                                          : startWidth) +
                                        (endWidthActual
                                          ? endWidthActual
                                          : endWidth)
                                      }% + ${
                                        (gridSpanActual
                                          ? gridSpanActual
                                          : gridSpan) - 1
                                      }px)`,
                                      backgroundColor: "#279989",
                                      color: "white",
                                      zIndex: 2,
                                      borderRadius: "8px",
                                      overflow: "hidden",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <RosterVisitCard
                                      visit={visit}
                                      view={view}
                                    />
                                  </Box>
                                  {focusedCardId === visit.id &&
                                    focusedUserId === user.userid && (
                                      <Box
                                        key={visit.id}
                                        sx={{
                                          position: "absolute",
                                          top:
                                            (showAbove ? -36 : 70) +
                                            (visit[`rowindex${user.userid}`] ||
                                              0) *
                                              79, // Show above or below based on space
                                          left: `${startWidth}%`,
                                          mt: showAbove ? "-9px" : "9px",
                                          width: "150px",
                                          border: "1px solid #279989",
                                          //borderTop: "0px",
                                          borderRadius: "8px",
                                          backgroundColor: "#ffffff",
                                          zIndex: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            padding: " 5px 10px",
                                            cursor: "pointer",
                                            borderBottom: "1px solid #279989",
                                            "&:hover": {
                                              backgroundColor: "#BFE1DC", // Hover color
                                              borderRadius: "8px 8px 0 0",
                                            },
                                          }}
                                          onClick={() => {
                                            setOpenAssignCarerDialog(true);
                                            setSelectedVisit(visit);
                                          }}
                                        >
                                          <Typography variant="body3">
                                            Change Users
                                          </Typography>
                                        </Box>
                                        <Box
                                          onClick={() => {
                                            setUnallocateUsersDialog(true);
                                            setSelectedVisit(visit);
                                          }}
                                          sx={{
                                            padding: "5px 10px",
                                            cursor: "pointer",
                                            "&:hover": {
                                              backgroundColor: "#BFE1DC", // Hover color
                                              borderRadius: "0 0 8px 8px",
                                            },
                                          }}
                                        >
                                          <Typography variant="body3">
                                            Unallocate Users
                                          </Typography>
                                        </Box>
                                      </Box>
                                    )}
                                  {hoveredCardId === visit.id &&
                                    hoveredUserId === user.userid && (
                                      <Box
                                        key={visit.id}
                                        sx={{
                                          position: "absolute",
                                          top:
                                            (showAbove ? -272 : 54) +
                                            (visit[`rowindex${user.userid}`] ||
                                              0) *
                                              79 +
                                            runrowspace +
                                            (showAbove
                                              ? -(
                                                  (visit.userid.length - 1) *
                                                  13
                                                )
                                              : 0), // Show above or below based on space
                                          left: `${startWidth}%`,
                                          mt: showAbove ? "-9px" : "9px",
                                          width: "250px",
                                          zIndex: 5,
                                        }}
                                      >
                                        <RosterVisitCardDetails visit={visit} />
                                      </Box>
                                    )}
                                </Box>
                              );
                            }
                            return null;
                          })}
                        {visits
                          .filter((event) => event.userid.includes(user.userid))
                          .map((visit) => {
                            const startIndex = getGridIndex(visit.startTime);
                            const endIndex = getGridIndex(visit.endTime);
                            const gridSpan = endIndex - startIndex;
                            const startWidth = getWidth(visit.startTime);
                            const endWidth = getWidth(visit.endTime);

                            if (gridIndex === startIndex) {
                              return (
                                <Box
                                  key={visit.id}
                                  sx={{
                                    position: "absolute",
                                    top:
                                      5 +
                                      (visit[`rowindex${user.userid}`] || 0) *
                                        79 +
                                      runrowspace,
                                    left: `${startWidth}%`,
                                    height: "54px",
                                    width: `calc(${
                                      gridSpan * 100 - startWidth + endWidth
                                    }% + ${gridSpan - 1}px)`,
                                    zIndex: 1,
                                    alignItems: "center",
                                    display: "flex",
                                    direction: "row",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      height: "17px",
                                      backgroundColor: "red",
                                      borderRadius: "10px",
                                      width: "2px",
                                    }}
                                  ></Box>
                                  <Box
                                    sx={{
                                      height: "2px",
                                      backgroundColor: "red",
                                      width: "100%",
                                    }}
                                  ></Box>
                                  <Box
                                    sx={{
                                      height: "17px",
                                      width: "2px",
                                      borderRadius: "10px",
                                      backgroundColor: "red",
                                    }}
                                  ></Box>
                                </Box>
                              );
                            }
                            return null;
                          })}
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          ))}
        </>
      )}
      {/* Client Rows with Visits */}
      {view === "Client View" && (
        <>
          {filteredClientList.map((Client) => (
            <Box
              key={Client.clientid}
              sx={{
                display: "flex",
                minHeight: "65px",
                height: `${5 + (Client.rowIndex + 1) * 59}px`,
              }}
            >
              <Box
                sx={{
                  width: "190px",
                  borderBottom: "1px solid #dddddd",
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "8px",
                }}
              >
                <ClientCard clientDetails={Client} />
              </Box>
              <Box sx={{ width: "100%", position: "relative" }}>
                <Grid
                  container
                  direction="row"
                  sx={{
                    height: "100%",
                    backgroundColor: "#E3F2F0",
                    borderBottom: "1px solid #dddddd",
                    borderRight: "1px solid #dddddd",
                  }}
                >
                  {visitSlots.map((_, gridIndex) => (
                    <Grid
                      item
                      xs={0.25}
                      key={gridIndex}
                      sx={{
                        borderLeft: "1px solid #dddddd",
                        position: "relative",
                      }}
                    >
                      {visits
                        .filter((event) => event.clientid === Client.clientid)
                        .map((visit) => {
                          const startIndex = getGridIndex(visit.startTime);
                          const endIndex = getGridIndex(visit.endTime);
                          const gridSpan = endIndex - startIndex;
                          const startWidth = getWidth(visit.startTime);
                          const endWidth = getWidth(visit.endTime);
                          const startIndexActual = getGridIndex(
                            visit.actualStartTime
                          );
                          const endIndexActual = getGridIndex(
                            visit.actualEndTime
                          );
                          const gridSpanActual =
                            endIndexActual - startIndexActual;
                          const startWidthActual = getWidth(
                            visit.actualStartTime
                          );
                          //console.log("startWidthActual", startWidthActual);
                          //console.log("startWidth", startWidth);
                          const endWidthActual = getWidth(visit.actualEndTime);

                          if (
                            gridIndex ===
                            (startIndexActual ? startIndexActual : startIndex)
                          ) {
                            return (
                              <Box
                                onMouseEnter={() => handleMouseEnter(visit.id)}
                                onMouseLeave={handleMouseLeave}
                                ref={(el) => cardRefs.current.set(visit.id, el)} // Set ref in the map
                                key={visit.id}
                              >
                                <Box
                                  key={visit.id}
                                  //ref={cardRef} // Attach ref to measure position
                                  sx={{
                                    position: "absolute",
                                    top: 5 + visit.clientRowIndex * 59,
                                    left: `${
                                      startWidthActual
                                        ? startWidthActual
                                        : startWidth
                                    }%`,
                                    height: "54px",
                                    width: `calc(${
                                      (gridSpanActual
                                        ? gridSpanActual
                                        : gridSpan) *
                                        100 -
                                      (startWidthActual
                                        ? startWidthActual
                                        : startWidth) +
                                      (endWidthActual
                                        ? endWidthActual
                                        : endWidth)
                                    }% + ${
                                      (gridSpanActual
                                        ? gridSpanActual
                                        : gridSpan) - 1
                                    }px)`,
                                    backgroundColor: "#ffffff",
                                    color: "white",
                                    zIndex: 2,
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    padding: 0,
                                    margin: 0,
                                    border:
                                      visit.userid.length === 0
                                        ? "1px solid #279989"
                                        : "none",
                                  }}
                                >
                                  <RosterVisitCard visit={visit} view={view} />
                                </Box>
                                {hoveredCardId === visit.id && (
                                  <Box
                                    key={visit.id}
                                    sx={{
                                      position: "absolute",
                                      top:
                                        (showAbove ? -272 : 54) +
                                        visit.clientRowIndex * 59 +
                                        (showAbove
                                          ? -((visit.userid.length - 1) * 13)
                                          : 0), // Show above or below based on space
                                      left: `${
                                        startWidthActual
                                          ? startWidthActual
                                          : startWidth
                                      }%`,
                                      mt: showAbove ? "-9px" : "9px",
                                      width: "250px",
                                      zIndex: 5,
                                    }}
                                  >
                                    <RosterVisitCardDetails visit={visit} />
                                  </Box>
                                )}
                              </Box>
                            );
                          }
                          return null;
                        })}

                      {visits
                        .filter((event) => event.clientid === Client.clientid)
                        .map((visit) => {
                          const startIndex = getGridIndex(visit.startTime);
                          const endIndex = getGridIndex(visit.endTime);
                          const gridSpan = endIndex - startIndex;
                          const startWidth = getWidth(visit.startTime);
                          const endWidth = getWidth(visit.endTime);

                          if (gridIndex === startIndex) {
                            return (
                              <Box
                                key={visit.id}
                                sx={{
                                  position: "absolute",
                                  top: 5 + visit.clientRowIndex * 59,
                                  left: `${startWidth}%`,
                                  height: "54px",
                                  width: `calc(${
                                    gridSpan * 100 - startWidth + endWidth
                                  }% + ${gridSpan - 1}px)`,
                                  zIndex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                  direction: "row",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "17px",
                                    backgroundColor: "red",
                                    borderRadius: "10px",
                                    width: "2px",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "2px",
                                    backgroundColor: "red",
                                    width: "100%",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "17px",
                                    width: "2px",
                                    borderRadius: "10px",
                                    backgroundColor: "red",
                                  }}
                                ></Box>
                              </Box>
                            );
                          }
                          return null;
                        })}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          ))}
        </>
      )}
      {openAssignCarerDialog && (
        <RosterAssignCarerDialog
          openDialog={openAssignCarerDialog}
          handleCloseDialog={() => {
            handleCloseAssignCarerDialog();
            setSelectedVisit([]);
          }}
          visits={visits}
          handlestep={handlestep}
          svisit={selectedVisit}
          carersList={Users}
        />
      )}

      {unallocateUsersDialog && (
        <ConfirmationDialog
          openDialog={unallocateUsersDialog}
          TitleText="Are you sure you want to Unallocate Users for the selected Visit"
          paraText="This will remove all the assigned users for this visit and move the visit to Unallocated"
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => {
            handleCloseUnallocateUsersDialog();
            setSelectedVisit([]);
            handlestep();
          }}
          onConfirmFun={() => {
            PostUnallocateUsers();
            handleCloseUnallocateUsersDialog();
            setSelectedVisit([]);
            handlestep();
          }}
          handleCloseDialog={() => {
            handleCloseUnallocateUsersDialog();
            setSelectedVisit([]);
          }}
        />
      )}
    </Box>
  );
}
