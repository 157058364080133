import React, { useEffect, useState } from 'react'
import { getCookie } from '../../../utils/utils';
import useStyles from '../../clients/styles/useStyles';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Paper, Typography } from '@mui/material';
import CustomButtom from '../../reusableComponents/CustomButton';
import CareboxLogo from "../../../assets/images/users/logo.jpg";
import agencyLogo from "../../../assets/images/users/agency2.jpg";
import jsPDF from "jspdf";
import "jspdf-autotable";

const doc = new jsPDF();
// const doc = new jsPDF({
//   orientation: "landscape", // Wide orientation
//   unit: "mm",
//   format: [420, 297], // Custom width and height
// });

const columns = [ 
  { field: 'userName', headerName: 'User Name',  headerAlign: 'center', align: 'center' },
  { field: 'status', headerName: 'Status',  headerAlign: 'center', align: 'center' },
  { field: 'contactNumber', headerName: 'Contact Number',  headerAlign: 'center', align: 'center' },
  {field: 'admin', headerName: 'Admin',  headerAlign: 'center', align: 'center' },
  {field: 'specialist', headerName: 'Specialist',  headerAlign: 'center', align: 'center' },
  {field: 'address', headerName: 'Address',  headerAlign: 'center', align: 'center' },
  {field: 'drive', headerName: 'Drive',  headerAlign: 'center', align: 'center' },
  {field: 'distanceTravelled', headerName: 'Distance Travelled',  headerAlign: 'center', align: 'center' },
  {field: 'driveExpiryDate', headerName: 'DriveExpiryDate', headerAlign: 'center', align: 'center' },
  {field: 'payPerMile', headerName: 'Pay Per Mile',  headerAlign: 'center', align: 'center' },
  {field: 'drivingLicenseNo', headerName: 'Driving License No',  headerAlign: 'center', align: 'center' },
];
const paginationModel = { page: 0, pageSize: 20 };

function MilesTravelled() {
  const [milesTravelled,setMilesTravelled] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const classes = useStyles()
  useEffect(()=>{
    const fetchUsersAPI = async () => {
      try {
        const token = getCookie();
        const URL = 'https://3.8.158.142:8443/api/getmilestravelled';
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
      console.log(data.data,'==============================================milestravelled');
  
        // setmedicationroute(transformedData);
      
        if (response.ok === true) {
          setMilesTravelled(data.data);
        } else {
          console.log("get all failed");  
          setMilesTravelled([]);
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };
    fetchUsersAPI();
  },[])
  const rows = milesTravelled.map((item,index) => ({
    id:index+1,
    userName: item.userName,
    status: item.status,
    contactNumber: item.contactNumber,
    admin: item.admin,
    specialist: item.specialist,
    address: item.address,
    drive: item.drive,
    distanceTravelled: item.distanceTravelled,
    driveExpiryDate: item.driveExpiryDate,
    payPerMile: item.payPerMile,
    drivingLicenseNo: item.drivingLicenseNo,
  }));
  const adjustedColumns = columns.map((col) => {
    const maxColumnWidth = Math.max(
      col.headerName.length * 9 + 1, // Header text width
      ...rows.map((row) => String(row[col.field]).length * 8 + 1) // Row text width
    );
    return {
      ...col,
      width: maxColumnWidth,
      minWidth: 70, // Set a minimum width to avoid extremely narrow columns
    };
  });
  const exportClientsPdf = () => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableMargin = 1; // Margin for the table on left and right
    const imageHeight = 13; // Fixed height for the images
    const imageYPosition = 10; // Fixed vertical position for the images
    const logoWidthLeft = 33; // Width for the left logo
    const logoWidthRight = 33; // Width for the right logo
  
    doc.setFillColor("f5fcff");
    doc.rect(0, 0, pageWidth, doc.internal.pageSize.getHeight(), "F"); // Page background
    doc.addImage(CareboxLogo, "JPEG", tableMargin, imageYPosition, logoWidthLeft, imageHeight); // Left logo
    doc.addImage(
      agencyLogo,
      "JPEG",
      pageWidth - tableMargin - logoWidthRight,
      imageYPosition,
      logoWidthRight,
      imageHeight
    );
    doc.setFontSize(24);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text(`User Miles Travelled Report`, 50, 25);
    doc.setFontSize(8);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toISOString().split("T")[0];
    doc.text(`Date: ${today}`, 15, 30);
  
    const clientColumns = [
      "userName",
      "status",
      "contactNumber",
      "admin",
     " specialist",
    ];
  
    const finalClients = rows.filter((u) => rowSelectionModel.includes(u.id));
    const body = 
        finalClients.map((d) => {
        return [
        d.userName,
        d.status,
        d.contactNumber,
        d.admin,
        d.specialist,
    ]})
    
  
    doc.autoTable({
      head: [clientColumns],
      body: body,
      startY: 33,
      tableWidth: "auto",
      margin: { top: 10, left: tableMargin, right: tableMargin },
      headStyles: {
        fillColor: "#279989",
        fontStyle: "normal",
        fontSize: 12,
        halign: "center", // Center-align header text horizontally
        valign: "middle", // Center-align header text vertically
        cellPadding: { top: 4, right: 1.5, bottom: 4, left: 1.5 }, // Adjust padding for better alignment
        textColor: "#FFFFFF", // White text for better contrast
      },
      styles: {
        fontSize: 13,
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
        overflow: "linebreak", // Ensures text wraps within the cell
      },
      bodyStyles: {
        cellPadding: { top: 5, right: 5, bottom: 5, left: 5 }, // Add padding to ensure the text doesn't touch the edges
        minCellHeight: 10, // Default minimum height for each cell
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
      },
      alternateRowStyles: { fillColor: "#e4f6f3" },
      columnStyles: {
        0: { cellWidth: "auto", halign: "center", valign: "middle" },
        1: { cellWidth: "auto", halign: "center", valign: "middle" },
        2: { cellWidth: "auto", halign: "center", valign: "middle" },
        3: { cellWidth: "auto", halign: "center", valign: "middle" },
        4: { cellWidth: "auto", halign: "center", valign: "middle" },
        5: { cellWidth: "auto", halign: "center", valign: "middle" },
        6: { cellWidth: "auto", halign: "center", valign: "middle" },
        7: { cellWidth: "auto", halign: "center", valign: "middle" },
        8: { cellWidth: "auto", halign: "center", valign: "middle" },
        9: { cellWidth: "auto", halign: "center", valign: "middle" },
        10: { cellWidth: "auto", halign: "center", valign: "middle" },
        11: { cellWidth: "auto", halign: "center", valign: "middle" },
      },
      didDrawCell: (data) => {
        const cellContent = data.cell.raw;
    
        // If the column has no values (e.g., "Specialist"), enforce center alignment
        if (data.column.index === 4 && !cellContent) {
          data.cell.styles.valign = "middle";
        }
      },
    });
    
  
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width / 2,
        287,
        { align: "center" }
      );
    }
    doc.save(`UserReportsByMilesTravelled_${today}.pdf`);
  };

  return (
    <Box sx={{ pt:4 ,px:3}}>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%",px:2,pb:4,alignItems: "center" }}>
      <Typography variant="h5"  sx={{p:0,m:0,color:"#279989"}}>
      Users Reports By Miles Travelled
      </Typography>
      <CustomButtom text="Download" disabled={rowSelectionModel.length === 0} onClick={exportClientsPdf}/>
      </Box>
       <Box className={classes.tableMainContainer}>
      <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 30, 40]}
          checkboxSelection 
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain} />
          </Box>
    </Box>
  )
}

export default MilesTravelled