import React, { useState, useEffect } from "react";
import { ClientAvatarForm } from "../aboutMe/ClientAvatarForm";
import {
  Box,
  Typography,
  Grid,
  Button,
  Autocomplete,
  FormLabel,
  TextField,
} from "@mui/material";
import { getCookie } from "../../../utils/utils";
import { ClientSideBarHeader } from "../clientReusableComponents/ClientSideBarHeader";
import UploadDocument from "../../../assets/images/clients/Upload_document.svg";
import Add from "../../../assets/images/clients/add.svg";

export const SettingsSideBar = ({
  clientId,
  clientMainData,
  setTabId,
  activeTabID,
}) => {
  const [userName, setUserName] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [mcnTabs, setMcnTabs] = useState([]);
  const setActiveTabID = (tab) => {
    setActiveTab(tab);
    setTabId(tab);
  };

  useEffect(() => {
    setActiveTab(activeTabID);
  }, [activeTabID]);

  useEffect(() => {
    if (clientMainData) {
      setUserName(
        `${clientMainData?.clientInfo?.first_name || ""} 
        ${clientMainData?.clientInfo?.middle_name || ""}
        ${clientMainData?.clientInfo?.last_name || ""}`
      );
    }
  }, [clientMainData]);

  return (
    <Box
      sx={{
        background: "#F0FCFA",
        borderRadius: "8px",
        padding: "10px",
        height: "100%",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column", // Ensure children stack vertically
      }}
    >
      <ClientSideBarHeader
        clientMainData={clientMainData}
        //isEditMode={isEditMode}
      />
    </Box>
  );
};
