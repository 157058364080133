import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/utils";
import useStyles from "../../clients/styles/useStyles";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Paper, Typography } from "@mui/material";
import CustomButtom from "../../reusableComponents/CustomButton";
import CareboxLogo from "../../../assets/images/users/logo.jpg";
import agencyLogo from "../../../assets/images/users/agency2.jpg";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CustomCalendar from "../../logs/CustomCalendar";
const doc = new jsPDF();

const columns = [
  {
    field: "userName",
    headerName: "User Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sessionType",
    headerName: "Session",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "visit_date",
    headerName: "Visit Date",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "actualStartTime",
    headerName: "Actual StartTime",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "actualEndTime",
    headerName: "Actual EndTime",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedStarttime",
    headerName: "Planned Starttime",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedEndtime",
    headerName: "Planned Endtime",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "actualTime",
    headerName: "Actual Time",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedTime",
    headerName: "planned Time",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "percOfLoggedHours",
    headerName: "PercOfLoggedHours",
    headerAlign: "center",
    align: "center",
  },
];
const paginationModel = { page: 0, pageSize: 20 };
function CareHoursLogged() {
  const [careHousLogged, setcareHousLogged] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const classes = useStyles();
 
 const fetchUsersAPI = async (fromDate, toDate) => {
  try {
    const token = getCookie();
    const URL = `${process.env.REACT_APP_BASE_URL}/getVisitsReportDetails?fromDate=${fromDate}&toDate=${toDate}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(URL, options);
    const data = await response.json();

    if (response.ok) {
      setcareHousLogged(data.data);
    } else {
      console.error("API call failed");
      setcareHousLogged([]);
    }
  } catch (e) {
    console.error("Error fetching data:", e.message);
  }
};

// Fetch data when the component mounts (initial load)
useEffect(() => {
  const initialStartDate = dayjs(startDate).format("YYYY-MM-DD");
  const initialEndDate = dayjs(endDate).format("YYYY-MM-DD");
  fetchUsersAPI(initialStartDate, initialEndDate);
}, []); // Empty dependency array ensures this runs only on the first render

// Fetch data when startDate or endDate changes
useEffect(() => {
  const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
  const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");

  fetchUsersAPI(updatedStartDate, updatedEndDate);
}, [startDate, endDate]); // Dependency array with startDate and endDate
  const rows = careHousLogged.map((item) => {
    // Clean up the userName string to remove square brackets and split names by commas if needed
    const cleanedUserNames = item.userName
      .replace(/[^\w\s,]/g, "") // Remove square brackets or any unwanted characters
      .split(",") // Split into individual names by comma
      .map((name) => name.trim()); // Trim any leading/trailing spaces from names
      const formattedVisitDate = dayjs(item.visit_date).format("DD-MM-YYYY");

    return {
      id: item.visitdetailsid, // Assuming visitdetailsid is present in item
      userName: cleanedUserNames.join(", "), // Join the cleaned names back with a comma
      sessionType: item.sessionType,
      visit_date: formattedVisitDate,
      actualStartTime: item.actualStartTime,
      actualEndTime: item.actualEndTime,
      plannedStarttime: item.plannedStarttime,
      plannedEndtime: item.plannedEndtime,
      actualTime: item.actualTime,
      plannedTime: item.plannedTime,
      percOfLoggedHours: item.percOfLoggedHours,
    };
  });
  const adjustedColumns = columns.map((col) => {
    const isAddressColumn = col.field === "userName"; // Check if it's the `userName` column
    return {
      ...col,
      flex: isAddressColumn ? 3 : 2, // Dynamic width for `userName`
      minWidth: isAddressColumn ? 260 : 130, // Ensure minimum width for each column
      renderCell: (params) => (
        <Box className={classes.gridColumns}
        >
          {params.value || "N/A"} {/* Display placeholder if value is empty */}
        </Box>
      ),
    };
  });
  const getDynamicRowHeight = (params) => {
    const rowContent = params?.row?.selectDays || params?.row?.userName || ""; // Combine both fields if necessary
    const estimatedLineCount = Math.ceil(rowContent.length / 30); // Estimate lines based on length
    return Math.max(70, estimatedLineCount * 25); // Minimum height is 70px
  };

  const exportClientsPdf = () => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableMargin = 1; // Margin for the table on left and right
    const imageHeight = 13; // Fixed height for the images
    const imageYPosition = 10; // Fixed vertical position for the images
    const logoWidthLeft = 33; // Width for the left logo
    const logoWidthRight = 33; // Width for the right logo

    doc.setFillColor("f5fcff");
    doc.rect(0, 0, pageWidth, doc.internal.pageSize.getHeight(), "F"); // Page background
    doc.addImage(
      CareboxLogo,
      "JPEG",
      tableMargin,
      imageYPosition,
      logoWidthLeft,
      imageHeight
    ); // Left logo
    doc.addImage(
      agencyLogo,
      "JPEG",
      pageWidth - tableMargin - logoWidthRight,
      imageYPosition,
      logoWidthRight,
      imageHeight
    );
    doc.setFontSize(24);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text(`Visit Report`, 75, 25);
    doc.setFontSize(8);
    doc.setTextColor(100, 100, 100);

    const today = new Date().toISOString().split("T")[0];
    doc.text(`Date: ${today}`, 15, 30);

    const clientColumns = [
      "userName",
      "Session",
      "Visit Date",
      "actualStartTime",
      "actualEndTime",
      "plannedStarttime",
    ];

    const finalClients = rows.filter((u) => rowSelectionModel.includes(u.id));
    const body = finalClients.map((d) => {
      const cleanedUserNames = d.userName
        .replace(/[^\w\s,]/g, "") // Remove square brackets or any unwanted characters
        .split(",") // Split into individual names by comma
        .map((name) => name.trim());
      return [
        cleanedUserNames.join(","),
        d.session,
        d.visit_date,
        d.actualStartTime,
        d.actualEndTime,
        d.plannedStarttime,
      ];
    });

    doc.autoTable({
      head: [clientColumns],
      body: body,
      startY: 33,
      tableWidth: "auto",
      margin: { top: 10, left: tableMargin, right: tableMargin },
      headStyles: {
        fillColor: "#279989",
        fontStyle: "normal",
        fontSize: 12,
        halign: "center", // Center-align header text horizontally
        valign: "middle", // Center-align header text vertically
        cellPadding: { top: 4, right: 1.5, bottom: 4, left: 1.5 }, // Adjust padding for better alignment
        textColor: "#FFFFFF", // White text for better contrast
      },
      styles: {
        fontSize: 13,
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
        overflow: "linebreak", // Ensures text wraps within the cell
      },
      bodyStyles: {
        cellPadding: { top: 5, right: 5, bottom: 5, left: 5 }, // Add padding to ensure the text doesn't touch the edges
        minCellHeight: 10, // Default minimum height for each cell
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
      },
      alternateRowStyles: { fillColor: "#e4f6f3" },
      columnStyles: {
        0: { cellWidth: "auto", halign: "center", valign: "middle" },
        1: { cellWidth: "auto", halign: "center", valign: "middle" },
        2: { cellWidth: "auto", halign: "center", valign: "middle" },
        3: { cellWidth: "auto", halign: "center", valign: "middle" },
        4: { cellWidth: "auto", halign: "center", valign: "middle" },
        5: { cellWidth: "auto", halign: "center", valign: "middle" },
        6: { cellWidth: "auto", halign: "center", valign: "middle" },
        7: { cellWidth: "auto", halign: "center", valign: "middle" },
        8: { cellWidth: "auto", halign: "center", valign: "middle" },
        9: { cellWidth: "auto", halign: "center", valign: "middle" },
        10: { cellWidth: "auto", halign: "center", valign: "middle" },
        11: { cellWidth: "auto", halign: "center", valign: "middle" },
      },
      didDrawCell: (data) => {
        const cellContent = data.cell.raw;

        // If the column has no values (e.g., "Specialist"), enforce center alignment
        if (data.column.index === 4 && !cellContent) {
          data.cell.styles.valign = "middle";
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width / 2,
        287,
        { align: "center" }
      );
    }
    doc.save(`UserReports_${today}.pdf`);
  };

  return (
    <Box sx={{ px: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          pb: 2,
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "45%" }}>
          <CustomCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
        <Typography variant="h5" sx={{ p: 0, m: 0, color: "#279989" }}>
          Users Reports By CareHoursLogged
        </Typography>
        <CustomButtom
          text="Download"
          disabled={rowSelectionModel.length === 0}
          onClick={exportClientsPdf}
        />
      </Box>
      <Box className={classes.tableMainContainer}>
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 30, 40]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          getRowHeight={getDynamicRowHeight}s
          className={classes.datagridmain}
        />
      </Box>
    </Box>
  );
}

export default CareHoursLogged;
