import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Typography, Grid, Checkbox } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../themes/Theme";
import Dropdown from "../reusableComponents/Dropdown";
import DragAndDrop from "../../assets/images/roster/draganddrop.svg";
import Calendar from "../reusableComponents/Calendar";
import TableDragIcon from "../../assets/images/roster/tabledragicon.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getCookie } from "../../utils/utils";
import dayjs from "dayjs";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FilterIcon from "@mui/icons-material/FilterList";
import DistanceMatrix from "../reusableComponents/DistanceMatrix";

export default function ManageRunVisits({
  setOpenManageRunVisitsForm,
  selectedRun,
  date,
}) {
  const [assignedVisits, setAssignedVisits] = useState([]);
  const [originalRunsList, setOriginaRunsList] = useState([]);
  const [visitsList, setVisitsList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(date).isValid() ? dayjs(date) : dayjs()
  );
  const [unassignedVisits, setUnassignedVisits] = useState(visitsList);
  const [selectedRunName, setSelectedRunName] = useState(selectedRun);
  const [openSessionsFilter, setOpenSessionsFilter] = useState(false);
  const [openLocationsFilter, setOpenLocationsFilter] = useState(false);
  const sessionDropdownRef = useRef(null); // Reference to the dropdown container

  // Detect clicks outside the dropdown
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       sessionDropdownRef.current &&
  //       !sessionDropdownRef.current.contains(event.target)
  //     ) {
  //       setOpenSessionsFilter(false); // Close the dropdown
  //     }
  //   };

  //   window.addEventListener("click", handleClickOutside);

  //   // Cleanup event listener on unmount
  //   return () => {
  //     window.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  const uniqueSessions = Array.from(
    new Set(unassignedVisits.map((visit) => visit.session))
  );

  const [selectedSessions, setSelectedSessions] = useState(uniqueSessions);

  const uniqueLocations = Array.from(
    new Set(unassignedVisits.map((visit) => visit.location))
  );

  const [selectedLocations, setSelectedLocations] = useState(uniqueLocations);

  useEffect(() => {
    setSelectedLocations([]);
    setSelectedSessions([]);
    //console.log(selectedRun.run_name, "123");
  }, [selectedDate, selectedRunName]);

  // Handle checkbox changes
  const handleSessionFilterCheckbox = (session) => (event) => {
    if (event.target.checked) {
      setSelectedSessions((prev) => [...prev, session]); // Add session to selected
    } else {
      setSelectedSessions((prev) => prev.filter((s) => s !== session)); // Remove session from selected
    }
  };

  const handleLocationFilterCheckbox = (location) => (event) => {
    if (event.target.checked) {
      setSelectedLocations((prev) => [...prev, location]); // Add session to selected
    } else {
      setSelectedLocations((prev) => prev.filter((l) => l !== location)); // Remove session from selected
    }
  };

  const filteredUnassignedVisits =
    selectedSessions.length || selectedLocations.length
      ? unassignedVisits.filter((visit) => {
          // Case 1: If selectedSessions is provided but selectedLocations is not
          if (selectedSessions.length && !selectedLocations.length) {
            return selectedSessions.includes(visit.session);
          }

          // Case 2: If selectedLocations is provided but selectedSessions is not
          if (!selectedSessions.length && selectedLocations.length) {
            return selectedLocations.includes(visit.location);
          }

          // Case 3: If both selectedSessions and selectedLocations are provided
          if (selectedSessions.length && selectedLocations.length) {
            return (
              selectedSessions.includes(visit.session) &&
              selectedLocations.includes(visit.location)
            );
          }

          // Default: If no filter criteria are applied, return all visits
          return true;
        })
      : unassignedVisits;

  const unfilteredVisits =
    selectedSessions.length || selectedLocations.length
      ? unassignedVisits.filter((visit) => {
          // Case 1: If selectedSessions is provided but selectedLocations is not
          if (selectedSessions.length && !selectedLocations.length) {
            return !selectedSessions.includes(visit.session);
          }

          // Case 2: If selectedLocations is provided but selectedSessions is not
          if (!selectedSessions.length && selectedLocations.length) {
            return !selectedLocations.includes(visit.location);
          }

          // Case 3: If both selectedSessions and selectedLocations are provided
          if (selectedSessions.length && selectedLocations.length) {
            return (
              !selectedSessions.includes(visit.session) &&
              !selectedLocations.includes(visit.location)
            );
          }

          // Default: If no filter criteria are applied, return all visits
          return true;
        })
      : [];

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setAssignedVisits([]);
  };

  const handleSessionFilterClick = (e) => {
    e.stopPropagation();
    setOpenSessionsFilter((prev) => !prev); // Toggle open/close on click
    setOpenLocationsFilter(false); // Toggle open/close on click
  };

  const handleLocationFilterClick = (e) => {
    e.stopPropagation();
    setOpenLocationsFilter((prev) => !prev); // Toggle open/close on click
    setOpenSessionsFilter(false); // Toggle open/close on click
  };

  const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD"); // Converts to dd-mm-yyyy

  // Handle drag end, determining where the visit was dropped
  const onDragEnd = (result) => {
    const { destination, source } = result;

    // If dropped outside of the list or in the same position
    if (
      !destination ||
      (destination.index === source.index &&
        destination.droppableId === source.droppableId)
    ) {
      return;
    }

    // If visit is dragged within the same list (Assigned or Unassigned)
    if (source.droppableId === destination.droppableId) {
      const visits =
        source.droppableId === "assigned"
          ? [...assignedVisits]
          : [...filteredUnassignedVisits];
      const [removed] = visits.splice(source.index, 1);
      visits.splice(destination.index, 0, removed);

      if (source.droppableId === "assigned") {
        setAssignedVisits(visits);
        console.log("No change Assigned visits", visits);
      } else {
        setUnassignedVisits(visits);
        console.log("No change UnAssigned visits", visits);
      }
    }
    // If visit is moved between lists (Assigned -> Unassigned or vice versa)
    else {
      let sourceVisits =
        source.droppableId === "assigned"
          ? [...assignedVisits]
          : [...filteredUnassignedVisits];
      let destinationVisits =
        destination.droppableId === "assigned"
          ? [...assignedVisits]
          : [...filteredUnassignedVisits];

      const [removed] = sourceVisits.splice(source.index, 1);
      destinationVisits.splice(destination.index, 0, removed);

      if (source.droppableId === "assigned") {
        setAssignedVisits(sourceVisits);
        console.log("sourceVisits", sourceVisits);
      } else {
        setUnassignedVisits([...sourceVisits, ...unfilteredVisits]);
        console.log("speacialcase", unfilteredVisits);
      }

      if (destination.droppableId === "assigned") {
        setAssignedVisits(destinationVisits);
        console.log("destinationVisits", destinationVisits);
      } else {
        setUnassignedVisits([...destinationVisits, ...unfilteredVisits]);
      }
    }
  };

  const fetchRunsAPI = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-get-all-runs`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok === true) {
        const modifiedRunsList = data.data.map((each) => ({
          ...each,
          id: each.id,
          text: each.run_name,
          value: each.run_name,
        }));

        setOriginaRunsList(modifiedRunsList);
      } else {
        console.log("Failed to fetch runs");
        setOriginaRunsList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  // Fetch runs on initial render
  useEffect(() => {
    fetchRunsAPI();
    //console.log(selectedRun.run_name, "123");
  }, []);

  const fetchVisitsAPI = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-get-all-open-visits?visit_date=${formattedDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok === true) {
        setVisitsList(data.data);
      } else {
        console.log("Failed to fetch runs");
        setVisitsList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  // Fetch runs on initial render
  useEffect(() => {
    fetchVisitsAPI();
    console.log("selectedDate", selectedDate);
    console.log("selectedRun", selectedRun);
  }, [selectedDate]);

  useEffect(() => {
    const assigned = visitsList.filter(
      (visit) => visit.runid === selectedRunName.id
    );
    const unassigned = visitsList.filter((visit) => visit.runid === "");

    setAssignedVisits(assigned);
    setUnassignedVisits(unassigned);
  }, [visitsList, selectedRunName]); // runs when visitsList changes or selectedrun changes

  const postAssignedVisits = async () => {
    try {
      console.log(assignedVisits);
      const body = {
        run_id: selectedRunName.id,
        visit_details_id: assignedVisits.map((visit) => visit.id),
        visit_date: dayjs(selectedDate).format("YYYY-MM-DD"),
      };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-run-visit-details-ids`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        setAssignedVisits([]);
      } else {
        console.log("Failed to set assigned visits");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const preFilledValue = originalRunsList.find(
    (option) => option.run_name === selectedRun?.run_name
  );

  const handleClose = () => setOpenManageRunVisitsForm(false);

  const handleSave = () => {
    postAssignedVisits();
    setOpenManageRunVisitsForm(false);
  };

  const handleRunChange = (value) => {
    setSelectedRunName(value);
  };

  return (
    <Box
      onClick={() => {
        setOpenLocationsFilter(false);
        setOpenSessionsFilter(false);
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <ThemeProvider theme={EditTheme}>
          {/* Heading */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              textAlign: "left",
              alignItems: "center",
              borderRadius: "10px",
              marginTop: "-20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                textAlign: "left",
                alignItems: "flex-start",
                borderRadius: "10px",
              }}
            >
              <Typography variant="h4">Assign Runs</Typography>{" "}
              <Typography
                variant="body1"
                sx={{ marginLeft: "-20px", marginTop: "-7px" }}
              >
                Utilise runs to consolidate visits and make the most of your
                schedule
              </Typography>
            </Box>
            <Box>
              <Button
                variant="outlinedSecondary"
                sx={{ marginLeft: "30px" }}
                onClick={() => setOpenManageRunVisitsForm(false)}
              >
                Back
              </Button>
            </Box>
          </Box>

          {/* Outer Container */}
          <Box
            sx={{
              border: "1px solid #27998966",
              borderRadius: "10px",
              marginTop: "10px",
              height: "calc(100vh - 220px)",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px",
            }}
          >
            {/* Left Container */}
            <Box
              sx={{
                borderRight: "1px solid #27998966",
                padding: "5px 10px 10px 10px",
                width: "35%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                textAlign: "left",
                alignItems: "flex-start",
              }}
            >
              {/* Sticky Header Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#ffffff", // to avoid content showing behind it
                  zIndex: 2, // ensures the header is above scrolling content
                  paddingRight: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ marginBottom: "-10px", marginRight: "10px" }}
                >
                  Select Run:
                </Typography>
                <Box sx={{ flex: 1 }}>
                  <Dropdown
                    list={originalRunsList}
                    nolabel
                    preFilledvalue={
                      preFilledValue ? preFilledValue.run_name : ""
                    }
                    onDropdownSelect={handleRunChange}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Box>

              {/* Scrollable Content Section */}
              <Box
                sx={{
                  overflow: "auto",
                  flex: 1,
                  paddingTop: "10px",
                  paddingRight: "10px", // Adds space between content and scrollbar
                  boxSizing: "border-box", // Ensures padding is within the width
                  width: "100%",
                }}
              >
                <Grid container gap={5}>
                  <Grid item xs={12}>
                    <Droppable droppableId="assigned">
                      {(provided) => (
                        <div
                          className="task-container"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            border: "1px solid #27998966",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                        >
                          {assignedVisits.length === 0 ? (
                            <Box>
                              <img
                                src={DragAndDrop}
                                alt="drag and drop"
                                style={{ width: "58%" }}
                              />
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  marginTop: "20px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography variant="body2">***Note</Typography>
                                <Typography variant="body2">
                                  Please drag and drop your open visits to
                                  complete a run
                                </Typography>
                              </Grid>
                            </Box>
                          ) : (
                            assignedVisits
                              .sort((a, b) => {
                                // Extract the start time (first part of the range) and convert it to a comparable time
                                const [aStart] = a.visit_time
                                  .split(" - ")
                                  .map((time) =>
                                    new Date(`1970-01-01T${time}:00`).getTime()
                                  );
                                const [bStart] = b.visit_time
                                  .split(" - ")
                                  .map((time) =>
                                    new Date(`1970-01-01T${time}:00`).getTime()
                                  );
                                return aStart - bStart;
                              })
                              .map((visit, index, sortedVisits) => (
                                <Draggable
                                  key={visit.id}
                                  draggableId={String(visit.id)}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      className="task-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {index != 0 && (
                                        <Box
                                          sx={{
                                            //border: "1px solid #279989",
                                            width: "100%",
                                            height: "70px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            // justifyContent: "space-between",
                                            // backgroundColor: "#EEFEFA",
                                            // borderRadius: "10px",
                                            // marginBottom: "0px",
                                            // padding: "5px 20px",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              //width: "0.5px",
                                              height: "20px",
                                              border: "0.5px solid #279989",
                                            }}
                                          ></Box>
                                          <Box
                                            sx={{
                                              width: "150px",
                                              height: "30px",
                                              border: "1px solid #279989",
                                              backgroundColor: "#EEFEFA",
                                              borderRadius: "6px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {" "}
                                            <DistanceMatrix
                                              origin={
                                                sortedVisits[index - 1].location
                                              }
                                              destination={visit.location}
                                            />
                                          </Box>
                                          <Box
                                            sx={{
                                              //width: "20px",
                                              height: "20px",
                                              border: "0.5px solid #279989",
                                            }}
                                          ></Box>
                                        </Box>
                                      )}
                                      <Box
                                        sx={{
                                          border: "1px solid #279989",
                                          width: "100%",
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                          backgroundColor: "#EEFEFA",
                                          borderRadius: "10px",
                                          marginBottom: "0px",
                                          padding: "5px 20px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            sx={{
                                              color: "#279989",
                                              width: "50%",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {visit.client_name}
                                          </Typography>
                                          <Typography
                                            variant="h6"
                                            sx={{ color: "#279989" }}
                                          >
                                            {visit.visit_time}
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            sx={{ color: "#279989" }}
                                          >
                                            {visit.location}
                                          </Typography>
                                          <Typography
                                            variant="h6"
                                            sx={{ color: "#279989" }}
                                          >
                                            {visit.number_of_carers_required}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </div>
                                  )}
                                </Draggable>
                              ))
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Grid>

                  <Grid item xs={12}>
                    <Button variant="customcancel" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="customsave" onClick={handleSave}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* Right Container */}
            <Box
              sx={{
                width: "65%",
                position: "relative",
                marginBottom: "10px",
                height: "100%",
              }}
            >
              {/* Header */}
              <Typography
                variant="h5"
                sx={{
                  marginTop: "15px",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%, 15%)",
                }}
              >
                Open Visits
              </Typography>

              {/* Header Calendar Content */}
              <Box>
                <Grid container>
                  <Grid item xs={4.5} sx={{ paddingLeft: "50px" }}>
                    <Box sx={{ width: "70%", marginTop: "5px" }}>
                      <Calendar
                        nolabel
                        onDateChange={handleDateChange}
                        preFilledvalue={selectedDate}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={7.5}></Grid>

                  {/* Table Header */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginLeft: "50px",
                      marginRight: "25px",
                      //position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        backgroundColor: "#279989",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        //position: "relative",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "white", width: "30%", textAlign: "left" }}
                      >
                        Client Name
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "white",
                          width: "15%",
                          position: "relative",
                        }}
                      >
                        Location
                        <FilterIcon
                          //ref={locationDropdownRef} // Attach the ref to the dropdown box
                          onClick={handleLocationFilterClick}
                          sx={{
                            cursor: "pointer",
                            width: "20px",
                          }}
                        ></FilterIcon>
                        {openLocationsFilter && (
                          <Box
                            // ref={locationDropdownRef}
                            sx={{
                              position: "absolute", // Ensures menu appears exactly below
                              border: "1px solid #cef4f4",
                              top: "33px", // Places it right below the header
                              //left: "20%", // Aligns with the "Location" header
                              zIndex: 10, // Ensures it's above other elements
                              backgroundColor: "white",
                              borderRadius: "4px",
                              width: "200px", // Adjust width as needed
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {uniqueLocations.map((location) => (
                              <MenuItem
                                key={location}
                                value={location}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  height: "40px",
                                  scroll: "auto",
                                  margin: "0px",
                                  cursor: "pointer", // Ensures the pointer changes to a hand
                                  "&:hover": {
                                    backgroundColor: "#f0f0f0", // Optional: Highlight on hover
                                  },
                                }}
                              >
                                <Checkbox
                                  checked={selectedLocations.includes(location)}
                                  onChange={handleLocationFilterCheckbox(
                                    location
                                  )}
                                />
                                <ListItemText
                                  primary={location}
                                  sx={{
                                    marginLeft: "-15px",
                                    whiteSpace: "nowrap", // Prevents wrapping
                                    overflow: "hidden", // Hides overflowed content
                                    textOverflow: "ellipsis", // Adds ellipsis for overflowed text
                                  }}
                                />
                              </MenuItem>
                            ))}
                          </Box>
                        )}
                      </Typography>

                      <Typography
                        variant="h6"
                        sx={{
                          color: "white",
                          width: "15%",
                          position: "relative",
                        }}
                      >
                        Session
                        <FilterIcon
                          ref={sessionDropdownRef} // Attach the ref to the dropdown box
                          onClick={handleSessionFilterClick}
                          sx={{ cursor: "pointer", width: "20px" }}
                        />
                        {openSessionsFilter && (
                          <Box
                            // ref={sessionDropdownRef}
                            sx={{
                              position: "absolute", // Ensures menu appears exactly below
                              border: "1px solid #cef4f4",
                              top: "33px", // Places it right below the header
                              //left: "470px", // Aligns with the "Session" header
                              zIndex: 10, // Ensures it's above other elements
                              backgroundColor: "white",
                              borderRadius: "4px",
                              width: "200px", // Adjust width as needed
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {uniqueSessions.map((session) => (
                              <MenuItem
                                key={session}
                                value={session}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  height: "40px",
                                  scroll: "auto",
                                  margin: "0px",
                                  cursor: "pointer", // Ensures the pointer changes to a hand
                                  "&:hover": {
                                    backgroundColor: "#f0f0f0", // Optional: Highlight on hover
                                  },
                                }}
                              >
                                <Checkbox
                                  checked={selectedSessions.includes(session)}
                                  onChange={handleSessionFilterCheckbox(
                                    session
                                  )}
                                />
                                <ListItemText
                                  primary={session}
                                  sx={{
                                    marginLeft: "-15px",
                                    whiteSpace: "nowrap", // Prevents wrapping
                                    overflow: "hidden", // Hides overflowed content
                                    textOverflow: "ellipsis", // Adds ellipsis for overflowed text
                                  }}
                                />
                              </MenuItem>
                            ))}
                          </Box>
                        )}
                      </Typography>

                      <Typography
                        variant="h6"
                        sx={{ color: "white", width: "25%" }}
                      >
                        Visit Time
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: "white", width: "15%" }}
                      >
                        No. of Carers
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* Scrollable Body Cards */}
              <Box
                sx={{
                  //maxHeight: "calc(100vh - 480px)",
                  overflowY: "scroll", // Enable vertical scrolling
                  marginLeft: "25px",
                  marginRight: "10px",
                  marginTop: "20px",
                  height: "calc(100% - 150px)",
                  paddingRight: "10px",
                }}
              >
                <Droppable droppableId="unassigned">
                  {(provided) => (
                    <div
                      className="task-container"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        minHeight: "calc(100vh - 480px)",
                      }}
                    >
                      {filteredUnassignedVisits
                        .sort((a, b) => {
                          // Extract the start time (first part of the range) and convert it to a comparable time
                          const [aStart] = a.visit_time
                            .split(" - ")
                            .map((time) =>
                              new Date(`1970-01-01T${time}:00`).getTime()
                            );
                          const [bStart] = b.visit_time
                            .split(" - ")
                            .map((time) =>
                              new Date(`1970-01-01T${time}:00`).getTime()
                            );
                          return aStart - bStart;
                        })
                        .map((visit, index) => (
                          <Draggable
                            key={visit.id}
                            draggableId={String(visit.id)}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="task-item"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Grid container key={index}>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "flex-end",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        marginRight: "10px",
                                        marginBottom: "9px",
                                      }}
                                    >
                                      <img
                                        src={TableDragIcon}
                                        alt="TableDragIcon"
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        width: "100%",
                                        border: "1px solid #279989",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        backgroundColor: "#EEFEFA",
                                        padding: "8px 16px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#279989",
                                          width: "30%",
                                          textAlign: "left",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {visit.client_name}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: "#279989", width: "15%" }}
                                      >
                                        {visit.location}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: "#279989", width: "15%" }}
                                      >
                                        {visit.session}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: "#279989", width: "25%" }}
                                      >
                                        {visit.visit_time}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: "#279989", width: "15%" }}
                                      >
                                        {visit.number_of_carers_required}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </DragDropContext>
    </Box>
  );
}
