import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/utils";
import { Box, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomButtom from "../../reusableComponents/CustomButton";
import CareboxLogo from "../../../assets/images/users/logo.jpg";
import agencyLogo from "../../../assets/images/users/agency2.jpg";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import useStyles from "../../clients/styles/useStyles";
import CustomCalendar from "../../logs/CustomCalendar";
const doc = new jsPDF();

const columns = [
  {
    field: "todoDescription",
    headerName: "Todo Description",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "selectDays",
    headerName: "Select Days",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "essential",
    headerName: "Essential",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sessionType",
    headerName: "Session",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "clientName",
    headerName: "Client Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "visitDate",
    headerName: "Visit Date",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "userName",
    headerName: "User Name",
    headerAlign: "center",
    align: "center",
  },
];
const paginationModel = { page: 0, pageSize: 20 };

function ListOfTodos() {
  const [listOfTodos, setListOfTodos] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const classes = useStyles();
  // useEffect(() => {    const fetchUsersAPI = async () => {
  //     try {
  //       const token = getCookie();
  //       // const URL = 'https://3.8.158.142:8443/api/getSubmittedToDosDetails';
  //       const URL = `${
  //         process.env.REACT_APP_BASE_URL
  //       }/getSubmittedToDosDetails?fromDate=${dayjs(startDate).format(
  //         "YYYY-MM-DD"
  //       )}&toDate=${dayjs(endDate).format("YYYY-MM-DD")}`;
  //       const options = {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       };
  //       const response = await fetch(URL, options);
  //       const data = await response.json();
  //       if (response.ok === true) {
  //         setListOfTodos(data.data);
  //       } else {
  //         console.log("get all failed");
  //         setListOfTodos([]);
  //       }
  //     } catch (e) {
  //       console.log("error", e.message);
  //     }
  //   };
  //   fetchUsersAPI();
  // }, [startDate, endDate]);

  const fetchUsersAPI = async (fromDate, toDate) => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/getSubmittedToDosDetails?fromDate=${fromDate}&toDate=${toDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await fetch(URL, options);
      const data = await response.json();
  
      if (response.ok) {
        setListOfTodos(data.data);
      } else {
        console.error("API call failed");
        setListOfTodos([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
  };
  // Fetch data when the component mounts (initial load)
  useEffect(() => {
    const initialStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const initialEndDate = dayjs(endDate).format("YYYY-MM-DD");
    fetchUsersAPI(initialStartDate, initialEndDate);
  }, []); // Empty dependency array ensures this runs only on the first render
  
  // Fetch data when startDate or endDate changes
  useEffect(() => {
    const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");
  
    fetchUsersAPI(updatedStartDate, updatedEndDate);
  }, [startDate, endDate]);
  const rows = listOfTodos.map((todo) => {
    const formattedVisitDate = dayjs(todo.visitDate).format("DD-MM-YYYY");
    return {
      id: todo.todoDetailsId,
      todoDescription: todo.todoDescription,
      selectDays: todo.selectDays,
      essential: todo.essential,
      sessionType: todo.sessionType,
      clientName: todo.clientName,
      visitDate: formattedVisitDate,
      userName: todo.userName,
    }
  });
  const adjustedColumns = columns?.map((col) => {
    const isExpandableColumn =
      col.field === "selectDays" ||
      col.field === "userName" ||
      col.field === "todoDescription";

    return {
      ...col,
      flex: isExpandableColumn ? 3.3 : 2, // Increase flex for expandable columns
      minWidth: isExpandableColumn ? 200 : 100, // Ensure minimum width for these columns
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "100%",
            whiteSpace: "normal",
            wordBreak: "break-word", // Break long words
            lineHeight: 1.2, // Adjust line spacing
          }}
        >
          {params.value || "N/A"}
        </div>
      ),
    };
  });
  const exportClientsPdf = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableMargin = 3; // Margin for the table on left and right
    const imageHeight = 13; // Fixed height for the images
    const imageYPosition = 10; // Fixed vertical position for the images
    const logoWidthLeft = 33; // Width for the left logo
    const logoWidthRight = 33; // Width for the right logo

    // Add background color
    doc.setFillColor("f5fcff");
    doc.rect(0, 0, pageWidth, doc.internal.pageSize.getHeight(), "F");

    // Add left and right logos aligned with the table
    doc.addImage(
      CareboxLogo,
      "JPEG",
      tableMargin,
      imageYPosition,
      logoWidthLeft,
      imageHeight
    ); // Left logo
    doc.addImage(
      agencyLogo,
      "JPEG",
      pageWidth - tableMargin - logoWidthRight,
      imageYPosition,
      logoWidthRight,
      imageHeight
    ); // Right logo
    // Add title
    doc.setFontSize(18);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text(
      "List of Todo's Reports",
      pageWidth / 2,
      imageYPosition + imageHeight / 2,
      { align: "center" }
    );
    // Add date below the title
    doc.setFontSize(12);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toISOString().slice(0, 10);
    doc.text(`Date: ${today}`, tableMargin, imageYPosition + imageHeight + 5);
    // Data Preparation
    const finalTodoReports = rows?.filter((u) =>
      rowSelectionModel?.includes(u.id)
    );
    if (!finalTodoReports.length) {
      console.error("No data available to display.");
      return;
    }

    const body = finalTodoReports?.map((todo) => {
      // const cleanedUserNames = todo.userName
      // .replace(/[^\w\s,]/g, '') // Remove square brackets or any unwanted characters
      // .split(',') // Split into individual names by comma
      // .map(name => name.trim()) // Trim any leading/trailing spaces from names
      return [
        todo.todoDescription,
        todo?.selectDays,
        todo?.essential,
        todo?.sessionType,
        todo?.clientName,
        todo?.visitDate,
        todo?.userName,
      ];
    });

    const clientColumns = [
      "Description",
      "Select Days",
      "Essential",
      "SessionType",
      "ClientName",
      "VisitDate",
      "UserName",
    ];
    doc.autoTable({
      head: [clientColumns], // Table header (column titles)
      body: body, // Table body (data rows)
      startY: 34,
      tableWidth: "auto", // Adjusts table width to content
      margin: { top: 10, left: 3, right: 3 }, // Table margin
      alternateRowStyles: { fillColor: "#e4f6f3" },
      columnStyles: {
        0: { cellWidth: "auto", halign: "center" },
        1: { cellWidth: "auto", halign: "center" },
        2: { cellWidth: "auto", halign: "center" },
        3: { cellWidth: "auto", halign: "center" },
        4: { cellWidth: "auto", halign: "center" },
        5: { cellWidth: "auto", halign: "center" },
        6: { cellWidth: "auto", halign: "center" },
      },
      headStyles: {
        halign: "center", // Center-align header cells
        valign: "middle", // Vertical alignment for header
        fillColor: "#279989",
        fontStyle: "Courier",
      },
      styles: {
        cellPadding: { top: 5.4, right: 1.5, bottom: 5.4, left: 1.5 },
      },
      didDrawCell: (data) => {},
    }); //   // Add page numbers
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    doc.setFillColor("f5fcff");
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        pageWidth / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    }
    // Save the PDF

    doc.save(`Todos_reports_${today}.pdf`);
  };
  const getDynamicRowHeight = (params) => {
    const rowContent = params?.row?.selectDays || params?.row?.userName || ""; // Combine both fields if necessary
    const estimatedLineCount = Math.ceil(rowContent.length / 30); // Estimate lines based on length
    return Math.max(70, estimatedLineCount * 25); // Minimum height is 70px
  };
  return (
    <Box sx={{ px: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          pb: 2,
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "45%" }}>
          <CustomCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
        <Typography variant="h5" sx={{ p: 0, m: 0, color: "#279989" }}>
          Users Reports By List of Todos
        </Typography>
        <CustomButtom
          text="Download"
          onClick={exportClientsPdf}
          disabled={rowSelectionModel.length === 0}
        />
      </Box>
      <Box className={classes.tableMainContainer}>
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 30, 40]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          getRowHeight={getDynamicRowHeight}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain}
          sx={{
            "& .MuiDataGrid-cell": {
              padding: "10px", // Add padding inside cells
              whiteSpace: "normal", // Allow text to wrap
              wordBreak: "break-word", // Break long words
            },
            "& .MuiDataGrid-root": {
              width: "100%", // Ensure table spans full width
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default ListOfTodos;
