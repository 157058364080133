import React, { useState } from 'react'
import CustomCalendar from '../../logs/CustomCalendar';
import { Box, Typography } from '@mui/material';
import dayjs from "dayjs";
import CustomButtom from '../../reusableComponents/CustomButton';

function SubMittedTodos() {
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  return (
    <Box sx={{ p: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%",px:2,pb:2,alignItems: "center" }}>
          <Box sx={{width:'45%'}}>

          <CustomCalendar 
           selectedDate={selectedDate} setSelectedDate={setSelectedDate} 
           startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
          </Box>
      <Typography variant="h5"  sx={{p:0,m:0,color:"#279989"}}>
      Users Reports By CareHoursLogged
      </Typography>
      <CustomButtom text="Download"  />
      </Box>
    </Box>
  )
}

export default SubMittedTodos