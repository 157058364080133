import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import {
  Name,
  Time,
  RadioInput,
  NumberInput,
  Calendar,
} from "../../../reusableComponents";
import Admin from "../../../../assets/images/users/Admin.svg";
import ConfirmationDialog from "../../../reusableComponents/ConfirmationDialog";
import dayjs from "dayjs";
import { getCookie } from "../../../../utils/utils";

const VisitDetailsEdit = ({
  visit,
  setEdit,
  setRefreshVisits,
  setHasUnsavedChanges,
}) => {
  const [startHour, startMinute] = visit.planned_start_time.split(":");
  const [endHour, endMinute] = visit.planned_end_time.split(":");
  const initialform = {
    carers: visit.number_of_carers_required,
    starthtime: startHour,
    startmtime: startMinute,
    endhtime: endHour,
    endmtime: endMinute,
    editoptions: "",
    startdate: visit.visit_date,
    enddate: visit.visit_date,
  };
  const [form, setForm] = useState(initialform);
  const [startingForm, setStartingForm] = useState(form);
  const currentDate = dayjs().format("YYYY-MM-DD");

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);
  const onClickYesDialogBtn = () => {
    setEdit(false);
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
  };
  useEffect(() => {
    setHasUnsavedChanges(false); // No unsaved changes on initial load
  }, [startingForm]);

  const carersRef = useRef();
  const starttimeRef = useRef();
  const endtimeRef = useRef();
  const editoptionsRef = useRef();
  const startdateRef = useRef();
  const enddateRef = useRef();

  const checkIsValidsGiven = () => {
    // Call the handleValidation method from the child components
    // if (startdateRef.current) {
    //   startdateRef.current.handleValidation();
    // }
    // if (enddateRef.current) {
    //   enddateRef.current.handleValidation();
    // }

    if (editoptionsRef.current) {
      editoptionsRef.current.handleValidation();
    }

    if (carersRef.current) {
      carersRef.current.handleValidation();
    }

    if (starttimeRef.current) {
      starttimeRef.current.handleValidation();
    }
    if (endtimeRef.current) {
      endtimeRef.current.handleValidation();
    }

    return (
      // startdateRef.current.handleValidation() &&
      // enddateRef.current.handleValidation() &&
      editoptionsRef.current.handleValidation() &&
      carersRef.current.handleValidation() &&
      starttimeRef.current.handleValidation() &&
      endtimeRef.current.handleValidation()
    );
  };
  const putVisitAPI = async () => {
    try {
      const token = getCookie();

      const body = {
        from_visit_date: form.startdate,
        until_visit_date: form.enddate,
        edit_options: form.editoptions,
        number_of_carers_required: form.carers,
        start_time: `${form.starthtime}:${form.startmtime}`,
        end_time: `${form.endhtime}:${form.endmtime}`,
      };

      console.log(body, "put api body................");
      const BASE_URL = "https://3.8.158.142:8443/";
      const URL = `${BASE_URL}api/visits/${visit.visit_details_id}`;
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        console.log("Data posted");
        setRefreshVisits(visit.visit_details_id);
        setEdit(false);
      } else {
        console.log("Failed to edit Visit.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const putVisit = () => {
    if (checkIsValidsGiven() === true) {
      putVisitAPI();
    }
  };

  const onConfirmFun = () => {
    putVisit();
    setHasUnsavedChanges(false); // Reset unsaved state
  };
  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };
  const onCancelFun = () => {
    if (!areObjectsEqual(form, startingForm)) {
      handleOpen();
    } else {
      setEdit(false);
      setHasUnsavedChanges(false); // Reset unsaved state
    }
  };
  return (
    <Box>
      <Grid
        container
        direction="row"
        alignItems="top"
        sx={{
          //marginTop: "20px",
          padding: "0px 12px",
          // "& > .MuiGrid-item": {
          //   marginTop: "20px", // Apply marginTop to all grid items
          // },
        }}
      >
        <Grid item xs={12} md={5.75}>
          <Name
            label="Vist Date"
            preFilledvalue={dayjs(visit.visit_date).format("DD-MM-YYYY")}
            disabled
          />
        </Grid>
        <Grid item md={0.5} xs={false} />
        <Grid item xs={12} md={5.75}>
          <Name label="Visit Day" preFilledvalue={visit.day_name} disabled />
        </Grid>
        <Grid item xs={12} md={5.75}>
          <NumberInput
            ref={carersRef}
            label="No.of Carers Required"
            onValueChange={(value) => {
              setForm((prevForm) => {
                const updatedForm = {
                  ...prevForm,
                  carers: value,
                };
                setHasUnsavedChanges(
                  !areObjectsEqual(updatedForm, startingForm)
                ); // Detect changes
                return updatedForm;
              });
            }}
            preFilledvalue={visit.number_of_carers_required}
            required
            enableSpinner
          />
        </Grid>
        <Grid item md={6.25} xs={false} />
        <Grid item xs={12} md={5.75}>
          <Time
            ref={starttimeRef}
            label="Start Time"
            required
            initialHours={startHour}
            initialMinutes={startMinute}
            onHoursChange={(value) => {
              setForm((prevForm) => {
                const updatedForm = {
                  ...prevForm,
                  starthtime: value,
                };
                setHasUnsavedChanges(
                  !areObjectsEqual(updatedForm, startingForm)
                ); // Detect changes
                return updatedForm;
              });
            }}
            onMinutesChange={(value) => {
              setForm((prevForm) => {
                const updatedForm = {
                  ...prevForm,
                  startmtime: value,
                };
                setHasUnsavedChanges(
                  !areObjectsEqual(updatedForm, startingForm)
                ); // Detect changes
                return updatedForm;
              });
            }}
          />
        </Grid>
        <Grid item md={0.5} xs={false} />
        <Grid item xs={12} md={5.75}>
          <Time
            ref={endtimeRef}
            label="End Time"
            required
            initialHours={endHour}
            initialMinutes={endMinute}
            minTime={{ hours: form.starthtime, minutes: form.startmtime }}
            onHoursChange={(value) => {
              setForm((prevForm) => {
                const updatedForm = {
                  ...prevForm,
                  endhtime: value,
                };
                setHasUnsavedChanges(
                  !areObjectsEqual(updatedForm, startingForm)
                ); // Detect changes
                return updatedForm;
              });
            }}
            onMinutesChange={(value) => {
              setForm((prevForm) => {
                const updatedForm = {
                  ...prevForm,
                  endmtime: value,
                };
                setHasUnsavedChanges(
                  !areObjectsEqual(updatedForm, startingForm)
                ); // Detect changes
                return updatedForm;
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {/* <Box>this is the main</Box> */}
          {/* <FormControl sx={{ marginTop: "10px" }}>
            <FormLabel sx={{ fontSize: "13px", marginBottom: "10px" }}>
              Please select the appropriate
            </FormLabel>
            <RadioGroup
              name="Mutiselect"
              //value={selectedTime}
              //onChange={handleChange} // Update the selected time when a radio button is clicked
            >
              
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "13px !important", // Adjust label font size
                  },
                }}
                value="Make changes to the visit only"
                control={<Radio />}
                label="Make changes to the visit only"
              />
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "13px !important", // Adjust label font size
                  },
                }}
                value="All Visits"
                control={<Radio />}
                label={`Make changes to all ${visit.session_type} Visits`}
              />
            </RadioGroup>
          </FormControl> */}
          <RadioInput
            ref={editoptionsRef}
            label="Please select the appropriate"
            onValueChange={(value) => {
              setForm((prevForm) => {
                const updatedForm = {
                  ...prevForm,
                  editoptions: value,
                };
                setHasUnsavedChanges(
                  !areObjectsEqual(updatedForm, startingForm)
                ); // Detect changes
                return updatedForm;
              });
            }}
            options={[
              {
                value: "Make changes to this visit only",
                label: "Make changes to this visit only",
              },
              {
                value: "All Visits",
                label: "Make changes to all " + visit.session_type + " Visits",
              },
            ]}
            required
          />
        </Grid>
        {form.editoptions === "All Visits" && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <Calendar
              ref={startdateRef}
              label="Start Date"
              onDateChange={(value) => {
                setForm((prevForm) => {
                  const updatedForm = {
                    ...prevForm,
                    startdate: value,
                  };
                  setHasUnsavedChanges(
                    !areObjectsEqual(updatedForm, startingForm)
                  ); // Detect changes
                  return updatedForm;
                });
              }}
              preFilledvalue={visit.visit_date}
              minDate={currentDate}
            />
            <Calendar
              ref={enddateRef}
              label="End Date"
              onDateChange={(value) => {
                setForm((prevForm) => {
                  const updatedForm = {
                    ...prevForm,
                    enddate: value,
                  };
                  setHasUnsavedChanges(
                    !areObjectsEqual(updatedForm, startingForm)
                  ); // Detect changes
                  return updatedForm;
                });
              }}
              minDate={form.startdate}
            />
          </Box>
        )}
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              margin: "10px 0px 0px 0px",
            }}
          >
            <Button
              variant="customcancel"
              onClick={onCancelFun}
              color="primary"
            >
              Cancel
            </Button>
            <Button variant="customsave" onClick={onConfirmFun} color="primary">
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
    </Box>
  );
};

export default VisitDetailsEdit;
